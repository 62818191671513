import {Entity} from "../../model";
import {Alert, Container} from "react-bootstrap";
import React, {useCallback} from "react";
import {ColumnDef, TableState} from "@tanstack/react-table";
import {SimpleList} from "../list/SimpleList";
import {CrudRepository} from "../../repository";
import {Loading} from "../Loading/Loading";
import {Page} from "../Page";
import {useKeycloak} from "@react-keycloak/web";
import {useFetchAll} from "../../utils/hook/useFetch";
import {useLocation} from "react-router-dom";

type EntityListTemplateProps<E extends Entity> = {
    title: string,
    repository: CrudRepository<E>,
    /**
     * Attributes of the Entity to display
     */
    columns: ColumnDef<E>[],
    createPath?: string,
    rearrangeable?: boolean,
    resizable?: boolean,
}

/**
 * Display Entity attributes
 */
export const EntityListTemplate = <E extends Entity>(props: EntityListTemplateProps<E>) => {

    const { rearrangeable, resizable } = props;
    const { keycloak } = useKeycloak();
    const location = useLocation();
    const repository = props.repository;

    const readAll = useCallback(() =>  {
        repository.setKeycloak(keycloak);
        return repository.readAll()
    },[keycloak, repository])

    const [ loading, data, error ] = useFetchAll(readAll);

    const storage_path =`list${location.pathname.replaceAll('/','_')}`

    const onSave = (config: any) => {
        console.log(config)
        localStorage.setItem(storage_path, JSON.stringify(config))
    }

    const initialState: TableState = JSON.parse(localStorage.getItem(storage_path) || '{}')

    const buildContent = () => {
        if (loading) {
            return (
                <div className={'d-flex-center'}>
                    <Loading/>
                </div>
            )
        }

        if (error) {
            return (
                <Alert variant={'danger'}>
                    {error}
                </Alert>
            )
        }

        if (!data) {
            return (
                <Alert variant={'warning'}>
                    Aucune donnée disponible
                </Alert>
            )
        }

        if (resizable || rearrangeable) {
            return (
                <Container>
                    <SimpleList<E>
                        data={data}
                        columns={props.columns}
                        createPath={props.createPath}
                        rearrangeable={rearrangeable}
                        resizable={resizable}
                        initialState={initialState}
                        onSave={onSave}
                    />
                </Container>
            )
        }

        return (
            <Container>
                <SimpleList<E>
                    data={data}
                    columns={props.columns}
                    createPath={props.createPath}
                />
            </Container>
        )
    }

    return (
        <Page type={"admin"}>
            <div className={'text-center position-relative w-100'}>
                <div
                    className={`ff-netto bg-dark swatch-primary text-center m-0 fs-3 w-100`}
                    style={{
                        lineHeight: '5rem',
                        height: '5rem',
                        display: 'inline-block',
                        padding: '0 2rem',
                    }}
                >
                    {`Administration • ${props.title}`}
                </div>
            </div>
            <div>
                {
                    buildContent()
                }
            </div>
        </Page>
    )

}