import axios from "axios";
import {Entity} from "../model";
import {getFieldValue} from "../utils/objectManipulation";
import Keycloak from "keycloak-js";

type Method =
    | "GET"
    | "POST"
    | "PATCH"
    | "PUT"
    | "DELETE"

export type CrudDataList<E extends Entity> = {
    nbItems: number;
    data: E[] | null;
};

export class CrudRepository<E extends Entity> {

    protected entityCode: string | null;
    protected updateMethod: Method = "PATCH";
    protected keycloak: Keycloak | null = null;

    protected contentTypes = {
        "CREATE": 'application/ld+json',
        "READ": 'application/ld+json',
        "UPDATE": 'application/ld+json',
        "DELETE": 'application/ld+json',
    }

    protected headers: any;

    constructor(entityCode: string, updateMethod: Method = "PATCH", keycloak: Keycloak | null = null) {
        this.entityCode = entityCode;

        if (updateMethod)
            this.updateMethod = updateMethod;

        this.setKeycloak(keycloak);

        this.show = this.show.bind(this)
    }

    public getEntityCode(): string | null {
        return this.entityCode;
    }

    public setKeycloak(keycloak: Keycloak | null) {
        this.keycloak = keycloak;
        this.headers = {
            Authorization: this.keycloak ? `Bearer ${this.keycloak.token}` : undefined,
            "Accept-Language": "fr-FR"
        }
    }

    /**
     * Promise used to fetch data asynchronously <br/>
     * axios is a fetch decorator
     * @returns array entities or null
     */
    public readAll(fields ?: string) : Promise<CrudDataList<E>> {
        return new Promise<CrudDataList<E>>((resolve, reject) => {
            axios({
                url:`${process.env.REACT_APP_SERVER_URL}/${this.entityCode}`,
                method: 'GET',
                headers: this.headers
            })
                .then((response: any) => {
                    resolve({
                        nbItems: response.data['hydra:totalItems'],
                        data: this.jsonToEntities(
                            response.data['hydra:member']
                        ),
                    });
                })
                .catch((error : any) => {
                    reject(error);
                })
        })
    }

    public show() : Promise<CrudDataList<E>> { return new Promise((resolve) => resolve({ nbItems: 0, data: [] })) }

    public jsonToEntities(json: any) : E[] {
        if (json == null) {
            return [];
        }
        let entities: E[] = [];
        for (let entry of json) {
            let entity: E | null = this.jsonToEntity(entry);
            if (entity != null) {
                entities.push(entity);
            }
        }
        return entities;
    }

    protected jsonToEntity(json: any): E | null {
        return null;
    }
    protected entityToJson(entity: E): any {
        return entity;
    }

    protected getIRI(id: number|string|object, path: string) {
        let calculated_id = id;
        if (typeof id === 'object') { calculated_id = `${getFieldValue(id, 'id')}`; }
        return `/${path}/${calculated_id}`
    }

    public read(id:number|string, fields ?: string): Promise<E | null>{
        return new Promise<E | null>((resolve, reject) =>{
                axios({
                    url: `${process.env.REACT_APP_SERVER_URL}/` + this.entityCode + '/' + id,
                    method: 'GET',
                    headers: {
                        ...this.headers,
                        'Content-Type': this.contentTypes["READ"]
                    }
                })
                    .then((response : any) => {
                        resolve(this.jsonToEntity(response.data));
                    })
                    .catch((error : any) => {
                        reject({
                            message: error?.statusText,
                            status: error?.status
                        });
                    })
            }
        );
    }

    public create(entity: E): Promise<E| null>{
        return new Promise<E| null>(async (resolve, reject) =>{
            axios({
                url: `${process.env.REACT_APP_SERVER_URL}/${this.entityCode}`,
                method: 'POST',
                headers: {
                    ...this.headers,
                    'Content-Type': this.contentTypes["CREATE"]
                },
                data: await this.entityToJson(entity),
            })
                .then((response : any) => {
                    resolve(this.jsonToEntity(response.data));
                })
                .catch((error : any) => {
                    reject({
                        message: error?.statusText,
                        status: error?.status
                    });
                })
        })

    }

    public update(entity: E): Promise<E| null>{
        return new Promise<E| null>(async (resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_SERVER_URL}/${this.entityCode}/${entity.id}`,
                method: this.updateMethod,
                headers: {
                    ...this.headers,
                    'Content-Type': this.contentTypes["UPDATE"]
                },
                data: await this.entityToJson(entity),
            })
                .then((response : any) => {
                    resolve(this.jsonToEntity(response.data));
                })
                .catch((error : any) => {
                    reject({
                        message: error?.statusText,
                        status: error?.status
                    });
                })
        })
    }

    public delete(id:number|string): Promise<void>{
        return new Promise<void>((resolve, reject) =>{
            axios({
                url: `${process.env.REACT_APP_SERVER_URL}/${this.entityCode}/${id}`,
                method: 'DELETE',
                headers: {
                    ...this.headers,
                    'Content-Type': this.contentTypes["DELETE"]
                },
                data: this.entityToJson,
            })
                .then((response : any) => {
                    resolve();
                })
                .catch((error : any) => {
                    reject({
                        message: error?.statusText,
                        status: error?.status
                    });
                })
        })
    }
}