import {Form} from "react-bootstrap";
import {useField, useFormikContext} from "formik";
import {FormFieldProps} from "./FormField";

export const FileFormField = (props: FormFieldProps) => {

    const [field] = useField(props.id);
    const { setFieldValue, setFieldTouched } = useFormikContext();

    const onChange = (e : any) => {
        if (e.currentTarget.files.length > 0)
            setFieldValue(`${field.name}`, e.currentTarget.files[0]);
        else
            setFieldValue(`${field.name}`, null)
    }

    console.log(props)

    return (
        <>
            <Form.Label htmlFor={props.id}>
                {props.label}
                {props.hint ? <span className={'hint'}>{` ${props.hint}`}</span> : null}
            </Form.Label>
            <Form.Control
                id={field.name}
                name={field.name}
                type={`file`}
                onChange={onChange}
                onFocus={() => setFieldTouched(field.name)}
                isInvalid={props.touched && !!props.error}
                isValid={props.touched && !props.error}
            />
        </>
    )
}