import React, {useState} from "react";
import {Doc} from "../core/types";
import {Button, Modal} from "react-bootstrap";

type VideoProps = {
    document: Doc
}

export const Video = ({document} : VideoProps) => {

    const now = new Date().getTime();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div
            className={`border rounded m-2 p-2 border-primary d-flex flex-column flex-lg-row gap-5 text-center text-lg-start align-items-center align-items-lg-stretch`}
            style={{
                backgroundColor: 'rgba(255,255,255,.5)'
            }}
        >
            <img
                src={`${process.env.REACT_APP_CONFIG_URL}/thumbnail/${document.thumbnail}?${now}`}
                alt={'thumbnail'}
                className={`rounded border`}
                style={{
                    height: 200,
                    width: 150,
                    objectFit: 'contain'
                }}
            />
            <div
                className={`d-flex flex-column align-items-stretch justify-content-between w-100`}
            >
                <div>
                    <h2 className={`ff-netto text-primary text-decoration-underline`}>{document.title}</h2>
                    <p className={'text-start'}>
                        {document.description}
                    </p>
                </div>
                <div className={`w-100 d-flex justify-content-center justify-content-lg-end`}>
                    <Button
                        variant={'outline-primary'}
                        style={{ right: '.5rem', bottom: '.5rem' }}
                        onClick={handleShow}
                    >
                        Lire la vidéo
                    </Button>
                </div>

            </div>
            <Modal
                show={show}
                onHide={handleClose}
                animation={false}
                backdrop="static"
                keyboard={false}
                fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title>{document.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: 'hidden'}}>
                    <iframe
                        title={`${document.title} youtube`}
                        width="100%"
                        height="100%"
                        src={`${document.file}?vq=hd1080`}
                        allowFullScreen
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}