import React from "react";
import {TemplateDirectory} from "../../../model";
import {ColumnDef} from "@tanstack/react-table";
import { DateCell} from "../../../component/list/cell";
import {EntityListTemplate} from "../../../component/template/EntityListTemplate";
import {TemplateDirectoryRepository} from "../../../repository";

export const ListTemplateDirectory = () => {

    const columns: ColumnDef<TemplateDirectory>[] = [
        {
            header: "Nom",
            accessorKey: 'name',
            id: 'name',
        },
        {
            header: "Permission",
            accessorFn: (data: TemplateDirectory) => data.permission ?? 'Accès libre',
            id: 'permission',
        },
        {
            header: 'Nombre de fichiers',
            accessorKey: 'numberOfFiles',
            id: 'numberOfFiles',
        },
        {
            header: `Dernière mise à jour`,
            accessorKey: `updatedAt`,
            id: `updatedAt`,
            cell: DateCell
        }
    ]

    return (
        <EntityListTemplate<TemplateDirectory>
            repository={new TemplateDirectoryRepository()}
            title={`Répertoire`}
            columns={columns}
            rearrangeable
        />
    )
}