import React from "react";
import {EntityReadTemplate} from "../../../component/template/EntityReadTemplate";
import {Tile} from "../../../model";
import {TileRepository} from "../../../repository";
import {GalleryTile} from "../../../component/Gallery";
import {useKeycloak} from "@react-keycloak/web";
import {Container} from "react-bootstrap";

export const ReadTile = () => {

    const {keycloak} = useKeycloak();

    return (
        <EntityReadTemplate<Tile>
            repository={new TileRepository(keycloak)}
            title={{
                page: `Tuile`,
                schema: `$0`,
                params: [`name`]
            }}
            attributes={[
                {label: `Nom`, key: 'name'},
                {label: `Description`, key: 'description'},
                {label: `Permission`, key: 'permission'},
                {label: `État`, key: 'state.name'},
                {label: `Catégorie`, key: 'category.name'},
                {label: `URL cible`, key: 'url', to: {path: 'url', external: true}},
                {
                    label: `Image`,
                    key: 'imageName',
                    fn: (value) => value && <img
                        alt={`tile`}
                        style={{maxHeight: '5rem', maxWidth: '100%'}}
                        src={`${process.env.REACT_APP_SERVER_URL}/images/tiles/${value}`}
                    />
                },
            ]}
        >
            {(data: Tile) => (
                <div style={{minHeight: '100%', display: 'grid', gridTemplateRows: '5rem 1fr', width: '100%'}}>
                    <div className={'text-center position-relative w-100'}/>
                    <Container className={'d-flex-center flex-column'}>
                        <div
                            className={'d-grid gap-4 w-100 '}
                            style={{
                                gridTemplateColumns: 'repeat(auto-fit, minmax(12rem, 1fr))',
                            }}
                        >
                            <div
                                className={`ff-titillium p-1 ps-2 rounded`}
                                style={{
                                    gridColumn: '1/-1',
                                    backgroundColor: 'rgba(255,255,255,.5)'
                                }}
                            >
                                <div className={`text-primary fs-4`}>{data.category?.name}</div>
                                <div>{data.category?.description}</div>
                            </div>
                            <GalleryTile
                                tile={data}
                                index={0}
                            />
                        </div>
                    </Container>
                </div>
                // <div
                //     className={'d-grid gap-4 w-100 '}
                //     style={{
                //         gridTemplateColumns: 'repeat(auto-fit, minmax(12rem, 1fr))',
                //     }}
                // >
                //     <GalleryTile
                //         tile={{
                //             title: data.name,
                //             code: `${data.permission}`,
                //             url: `${data.url}`,
                //             state: data.state && data.state.name as any,
                //             image: `${data.imageName}`,
                //             explanation: data.description
                //         }}
                //         index={0}
                //     />
                // </div>
            )}
        </EntityReadTemplate>
    )
}