export class FormHelper {
    /**
     * Get empty string if value is null or undefined
     * @param value
     */
    public cleanInitialValue (value: any) {
        if (value === null) { return ''; }
        if (value === undefined) { return ''; }
        return value;
    }
}