import {Alert} from "react-bootstrap";

type OperationProps = {
    typeOperation: `Travaux` | `Maitrise d'oeuvre externe` | `Autre` | `MOI`
}

export class Operation {
    public typeOperation: `Travaux` | `Maitrise d'oeuvre externe` | `Autre` | `MOI`
    public errors: { [key: string]: string[] }
    public data: any

    public constructor(props: OperationProps) {
        this.typeOperation = props.typeOperation
        this.errors = {}
    }

    public isValid() {
        for (const key in this.errors) {
            if (this.errors[key].length > 0) {
                return false;
            }
        }
        return true;
    }

    protected validate(): void {
        const test = this.isFullOfStrings(this.data)
        if (!test.valid) {
            this.errors['structure'] = test.error_keys.map(key => `${key} doit être sous la forme d'une chaine de caractères.`)
        }
    }

    protected isFullOfStrings(data: any) : { valid: boolean, error_keys: string[] } {
        const keys: string[] = []
        for (const key in data) {
            if (typeof (data as any)[key] === 'object') {
                keys.push(...this.isFullOfStrings((data as any)[key]).error_keys.map(k => `${key} > ${k}`))
            }
            else if (typeof (data as any)[key] !== 'string') {
                keys.push(key)
            }
        }
        return ({
            valid: keys.length === 0,
            error_keys: keys
        })
    }
}

export class BaseOperation extends Operation {

    public codeAode: string
    public data: BaseOperationProps

    public constructor(props: BaseOperationProps) {
        super(props);
        this.codeAode = props.codeAode
        this.data = props
        this.validate()
    }

    protected validate() {
        super.validate();
    }
}

export class MoiOperation extends Operation {

    public tauxMOI: string
    public tauxSubventionMOI: string
    public data: MoiOperationProps

    public constructor(props: MoiOperationProps) {
        super(props);
        this.tauxMOI = props.tauxMOI
        this.tauxSubventionMOI = props.tauxSubventionMOI
        this.data = props
        this.validate()
    }

    protected validate() {
        super.validate();

        this.errors['main'] = []

        if (isNaN(Number(this.data.tauxMOI))) {
            this.errors['main'].push('tauxMOI doit être un nombre entier ou décimal.');
        }
        if (isNaN(Number(this.data.tauxSubventionMOI))) {
            this.errors['main'].push('tauxSubventionMOI doit être un nombre entier ou décimal.');
        }
    }
}

function isMoiOperation(operation: Operation): operation is MoiOperation {
    return operation.typeOperation === 'MOI';
}

function isBaseOperation(operation: Operation): operation is BaseOperation {
    return operation.typeOperation !== 'MOI';
}

type BaseOperationProps = {
    typeOperation: `Travaux` | `Maitrise d'oeuvre externe` | `Autre`,
    // Précisez si typePrestation = Autre (longueur max: 30 caractères)
    typeAutre: string,
    // 0=actif, 1=en pause, 2=achevé (valeurs possibles: 0, 1 ou 2)
    etatOperation: "0" | "1" | "2",
    // Code de l'opération donné par l'AODE  (donnée unique, longueur max: 20 caractères)
    codeAode: string,
    // Autre intitulé de l'opération (longueur max: 50 caractères)
    autreIntitule: string,
    // Nom de la commune principale supportant l'opération (longueur max: 100 caractères)
    nomCommune: string,
    // Lieu-dit de l'opération (longueur max: 100 caractères)
    lieuDit: string,
    // Date de la commande pressentie (valeurs possibles: YYYY-MM-DD)
    dateCommande: string,
    // Date de la réception pressentie (valeurs possibles: YYYY-MM-DD)
    dateReception: string,
    // Montant estimé des travaux HT (valeurs possibles: nombres entiers ou décimaux)
    montantTravaux: string,
    // Taux de subvention demandé (valeurs possibles: nombres entiers ou décimaux)
    tauxSub: string,
    // L'opération contient la Maitrise d'Oeuvre Interne (valeurs possibles: true ou false)
    contientMOI: `true` | `false`,
    caracteristiqueTensionHTA: HTAData,
    caracteristiqueTensionBT: BTData,
    autresFinanceurs: Funder[],
    marches: Market[],
    // Date de la commande du paiement (valeur possible: YYYY-MM-DD)
    dateCommandePaiement: string,
    mandats: Mandat[],
    // Montant des travaux cumulés HT (valeurs possibles: nombres entiers ou décimaux), ce montant est soit identique au dernier paiement si aucun acompte n'est demandé sur cette opération soit est supérieur au cumul antérieur si un acompte/solde est demandé sur cette opéaration. Pour le solde, ce montant est le montant réel définitif des travaux effectués sur l'opérations
    montantTravauxCumules: string,
    // Cumule inéligible HT (valeurs possibles: nombres entiers ou décimaux), ce monant est soit identique au cumul affiché sur le dernier paiement si aucun cumul inéligible est ajouté depuis le paiement antérieur soit est supérieur cumul indiqué sur le paiement antérieur si un cumul inéligible est ajouté depuis le paiement antérieur.
    cumuleIneligible: string,
    // Commentaire liée à une demande de paiement sur cette opération (longueur max: 100 caractères)
    observation: string
}

type MoiOperationProps = {
    typeOperation: `MOI`,
    tauxMOI: string,
    tauxSubventionMOI: string
}

type HTAData = {
    // [Désactivé pour l'instant]Réseaux HTA : Situation URBAINE ou RURALE ou TRES_RURALE
    "ptSituation": `URBAINE` | `RURALE` | `TRES_RURALE`,
    // Ancien champ du nombre de départ existant qui devient Nombre de DMA à résorber (valeurs possibles: nombres entiers)
    "ptNbDMA": string,
    // Ouvrages HTA à déposer ou supprimer : Réseau linéaire aérien (valeurs possibles: nombres entiers ou décimaux)
    "odsReseauAerien": string,
    // Projets HTA : Réseau linéaire aérien (valeurs possibles: nombres entiers ou décimaux)
    "ptReseauAerien": string,
    // Ouvrages HTA à déposer ou supprimer : Réseau linéaire souterrain (valeurs possibles: nombres entiers ou décimaux)
    "odsReseauSouterrain": string,
    // Projets HTA : Réseau linéaire aérien (valeurs possibles: nombres entiers ou décimaux)
    "ptReseauSouterrain": string,
    // Ouvrages HTA à déposer ou supprimer : Nombre de postes de transformation HTA/BT (valeurs possibles: nombres entiers)
    "odsNbTransformateur": string,
    // Projets HTA : Nombre de postes de transformation HTA/BT (valeurs possibles: nombres entiers)
    "ptNbTransformateur": string,
    // Ouvrage HTA: ancien champ  de l'Année d'origine principale devenue année représentative du réseau (valeurs possibles: YYYY <= année en cours)
    "odsAnneeRepresentative": string,
    // Projets HTA : Nombre de transformateur à renforcer (valeurs possibles: nombres entiers)
    "ptNbTransformateurAModifierOuMuter": string,
    // [Désactivé pour l'instant]Projets HTA : Nombre de supports à remplacer (en unités) [Désactivé pour l'instant]
    "ptNbSupportARemplacer": string,
    // Solde HTA : Champs de l'année de mise en service
    "pourSoldeAnneeMES": string,
    // Commentaire réseaux (longueur max: 300 caractères)
    "commentaires": string
}

type BTData = {
    //[Désactivé pour l'instant] Réseaux BT : Situation URBAINE ou RURALE ou TRES_RURALE [Désactivé pour l'instant]
    "ptSituation": `URBAINE` | `RURALE` | `TRES_RURALE`,
    // Projets BT : Nombre de CMA à traiter (en unités) [Désactivé pour l'instant]
    "ptNbCmaATraiter": string,
    // Ouvrages BT à déposer ou supprimer : Réseau en façade (valeurs possibles: nombres entiers ou décimaux)
    "odsReseauFacade": string,
    // Projets BT : Réseau en façade (valeurs possibles: nombres entiers ou décimaux)
    "ptReseauFacade": string,
    // Ouvrages BT à déposer ou supprimer : Réseau linéaire souterrain (valeurs possibles: nombres entiers ou décimaux)
    "odsReseauSouterrain": string,
    // Projets BT : Réseau linéaire souterrain (valeurs possibles: nombres entiers ou décimaux)
    "ptReseauSouterrain": string,
    // Projets BT : Détail aérien - Câbles torsadés (valeurs possibles: nombres entiers ou décimaux)
    "ptFilTorsade": string,
    // Ouvrages BT à déposer ou supprimer : Détail aérien - Fils nus (valeurs possibles: nombres entiers ou décimaux)
    "odsFilNu": string,
    // Ouvrages BT à déposer ou supprimer : Détail aérien - Câbles torsadés (valeurs possibles: nombres entiers ou décimaux)
    "odsFilTorsade": string,
    // [Désactivé pour l'instant]Projets BT : Nombre de supports à remplacer (en unités) [Désactivé pour l'instant]
    "ptNbSupportARemplacer": string,
    // [Désactivé pour l'instant]Ouvrages BT : Nombre de branchements totaux (en unités) [Désactivé pour l'instant]
    "odsNbBranchementTotaux": string,
    // Projets BT : Nombre de branchements totaux (en unités)qui reprend la somme des branchement à renouveler, nouveaux et raccordés 
    "ptNbBranchementTotaux": string,
    // Ouvrage BT: ancien champ  de l'Année d'origine principale devenue année représentative du réseau (valeurs possibles: YYYY <= année en cours)
    "odsAnneeRepresentative": string,
    // Solde BT: Le nombre de branchement aérien raccordé sans modification faisant partie du nombre de branchement total.
    "pourSoldeDontBranchementAerienRaccordeeSansModif": string,
    // Solde BT: Le nombre de branchement aérien renouvelés faisant partie du nombre de branchement total.
    "pourSoldeDontBranchementAerienRenouveles": string,
    // Solde BT: Le nombre de branchement aérien nouveaux faisant partie du nombre de branchement total.
    "pourSoldeDontBranchementAerienNouveaux": string,
    // Solde BT: Le nombre de branchement souterrain renouvelés faisant partie du nombre de branchement total.
    "pourSoldeDontBranchementSouterrainRenouveles": string,
    // Solde BT: Le nombre de branchement souterrain nouveaux faisant partie du nombre de branchement total.
    "pourSoldeDontBranchementSouterrainNouveaux": string,
    // Solde BT: Année de mise en service
    "pourSoldeAnneeMES": string,
    // Commentaire réseaux  (longueur max: 300 caractères)
    "commentaires": string
}

type Funder = {
    "typeFinancement": string,
    "identite": string,
    // Montant de la participation : Détail aérien - Câbles torsadés (valeurs possibles: nombres entiers ou décimaux)
    "montantParticipation": string,
    // Taux de participation : Détail aérien - Câbles torsadés (valeurs possibles: nombres entiers ou décimaux)
    "tauxParticipation": string
}

type Market = {
    "code": string
}

type Mandat = {
    // Numéro du mandat (valeurs possibles: nombres entiers)
    "numero": string,
    // Date du mandat (valeur possible: YYYY-MM-DD)
    "date": string,
    // Montant HT du mandat (valeurs possibles: nombres entiers ou décimaux)
    "montant": string,
    // Montant TTC du mandat (valeurs possibles: nombres entiers ou décimaux)
    "montantTtc": string
}

const validateOperation = (operation: any): { valid: boolean, errors: string[] } => {
    const errors: string[] = [];
    const typeOperationValues = ["Travaux", "Maitrise d'oeuvre externe", "Autre", "MOI"];
    const etatOperationValues = ["0", "1", "2"];
    const contientMOIValues = ["true", "false"];

    if (typeof operation !== 'object' || operation === null) {
        errors.push('Impossible de lire cette opération.');
    }
    else {
        if (!typeOperationValues.includes(operation.typeOperation)) {
            errors.push('typeOperation non valide.');
            return {
                valid: errors.length === 0,
                errors
            };
        }

        switch (operation.typeOperation) {
            case "MOI":
                if (typeof operation.tauxMOI !== 'string' || isNaN(Number(operation.tauxMOI))) {
                    errors.push('tauxMOI doit être un nombre entier ou décimal.');
                }
                if (typeof operation.tauxSubventionMOI !== 'string' || isNaN(Number(operation.tauxSubventionMOI))) {
                    errors.push('tauxSubventionMOI doit être un nombre entier ou décimal.');
                }
                break;
            default:
                if (operation.typeOperation === "Autre" && (typeof operation.typeAutre !== 'string' || operation.typeAutre.length === 0)) {
                    errors.push('typeAutre doit être complété si typeOperation est "Autre".');
                }

                if (operation.typeOperation === "Autre" && (typeof operation.typeAutre !== 'string' || operation.typeAutre.length > 30)) {
                    errors.push('typeAutre ne doit pas dépasser 30 caractères.');
                }

                if (!etatOperationValues.includes(operation.etatOperation)) {
                    errors.push('etatOperation non valide.');
                }

                if (typeof operation.codeAode !== 'string' || (operation.codeAode.length > 0 && operation.codeAode.length > 20)) {
                    errors.push('codeAode must be a string with a maximum length of 20.');
                }

                if (typeof operation.autreIntitule !== 'string' || operation.autreIntitule.length > 50) {
                    errors.push('autreIntitule must be a string with a maximum length of 50.');
                }

                if (typeof operation.nomCommune !== 'string' || operation.nomCommune.length > 100) {
                    errors.push('nomCommune must be a string with a maximum length of 100.');
                }

                if (typeof operation.lieuDit !== 'string' || operation.lieuDit.length > 100) {
                    errors.push('lieuDit must be a string with a maximum length of 100.');
                }

                if (typeof operation.dateCommande !== 'string' || !/^\d{4}-\d{2}-\d{2}$/.test(operation.dateCommande)) {
                    errors.push('dateCommande doit être une date au format AAAA-MM-JJ.');
                }

                if (typeof operation.dateReception !== 'string' || !/^\d{4}-\d{2}-\d{2}$/.test(operation.dateReception)) {
                    errors.push('dateReception doit être une date au format AAAA-MM-JJ.');
                }

                if (typeof operation.montantTravaux !== 'string' || isNaN(Number(operation.montantTravaux))) {
                    errors.push('montantTravaux doit être un nombre entier ou décimal.');
                }

                if (typeof operation.tauxSub !== 'string' || isNaN(Number(operation.tauxSub))) {
                    errors.push('tauxSub doit être un nombre entier ou décimal.');
                }

                if (!contientMOIValues.includes(operation.contientMOI)) {
                    errors.push('Invalid contientMOI.');
                }

                const testHTA = validateHTAData(operation.caracteristiqueTensionHTA)
                if (!testHTA.valid) {
                    errors.push(...testHTA.errors);
                }

                const testBT = validateBTData(operation.caracteristiqueTensionBT)
                if (!testBT.valid) {
                    errors.push(...testBT.errors);
                }

                if (!Array.isArray(operation.autresFinanceurs) || !operation.autresFinanceurs.every(validateFunder)) {
                    errors.push('Invalid autresFinanceurs.');
                }

                if (!Array.isArray(operation.marches) || !operation.marches.every(validateMarket)) {
                    errors.push('Invalid marches.');
                }

                if (typeof operation.dateCommandePaiement !== 'string' || (operation.dateCommandePaiement.length > 0 && !/^\d{4}-\d{2}-\d{2}$/.test(operation.dateCommandePaiement))) {
                    errors.push('dateCommandePaiement doit être une date au format AAAA-MM-JJ.');
                }

                if (!Array.isArray(operation.mandats) || !operation.mandats.every(validateMandat)) {
                    errors.push('Invalid mandats.');
                }

                if (typeof operation.montantTravauxCumules !== 'string' || isNaN(Number(operation.montantTravauxCumules))) {
                    errors.push('montantTravauxCumules must be a string representing a number.');
                }

                if (typeof operation.cumuleIneligible !== 'string' || isNaN(Number(operation.cumuleIneligible))) {
                    errors.push('cumuleIneligible must be a string representing a number.');
                }

                if (typeof operation.observation !== 'string' || operation.observation.length > 100) {
                    errors.push('observation must be a string with a maximum length of 100.');
                }
                break;
        }
    }

    return {
        valid: errors.length === 0,
        errors
    };
}

const validateHTAData = (data: any): { valid: boolean, errors: string[] } => {
    const errors: string[] = [];
    const situationValues = ["URBAINE", "RURALE", "TRES_RURALE"];

    if (!data) {
        return {
            valid: false,
            errors: ["Aucune donnée HTA"]
        };
    }

    if (!situationValues.includes(data.ptSituation)) {
        errors.push('Invalid ptSituation.');
    }
    if (typeof data.ptNbDMA !== 'string' || isNaN(Number(data.ptNbDMA))) {
        errors.push('Invalid ptNbDMA.');
    }
    if (typeof data.odsReseauAerien !== 'string' || isNaN(Number(data.odsReseauAerien))) {
        errors.push('Invalid odsReseauAerien.');
    }

    return {
        valid: errors.length === 0,
        errors
    };
}

const validateBTData = (data: any): { valid: boolean, errors: string[] } => {
    const errors: string[] = [];
    const situationValues = ["URBAINE", "RURALE", "TRES_RURALE"];

    if (!data) {
        return {
            valid: false,
            errors: ["Aucune donnée HTA"]
        };
    }

    if (!situationValues.includes(data.ptSituation)) {
        errors.push('Invalid ptSituation.');
    }
    if (typeof data.ptNbCmaATraiter !== 'string' || isNaN(Number(data.ptNbCmaATraiter))) {
        errors.push('Invalid ptNbCmaATraiter.');
    }
    if (typeof data.odsReseauFacade !== 'string' || isNaN(Number(data.odsReseauFacade))) {
        errors.push('Invalid odsReseauFacade.');
    }

    return {
        valid: errors.length === 0,
        errors
    };
}

const validateFunder = (data: any): data is Funder => {
    const errors: string[] = [];

    if (!data) {
        return false;
    }

    if (typeof data.typeFinancement !== 'string') {
        errors.push('Invalid typeFinancement.');
    }
    if (typeof data.identite !== 'string') {
        errors.push('Invalid identite.');
    }
    if (typeof data.montantParticipation !== 'string' || isNaN(Number(data.montantParticipation))) {
        errors.push('Invalid montantParticipation.');
    }
    if (typeof data.tauxParticipation !== 'string' || isNaN(Number(data.tauxParticipation))) {
        errors.push('Invalid tauxParticipation.');
    }

    if (errors.length > 0) {
        console.log('Funder errors:', errors);
        return false;
    }
    return true;
}

const validateMarket = (data: any): data is Market => {
    const errors: string[] = [];

    if (!data) {
        return false;
    }

    if (typeof data.code !== 'string') {
        errors.push('Invalid code.');
    }

    if (errors.length > 0) {
        console.log('Market errors:', errors);
        return false;
    }
    return true;
}

const validateMandat = (data: any): data is Mandat => {
    const errors: string[] = [];
    if (typeof data.numero !== 'string' || isNaN(Number(data.numero))) {
        errors.push('Invalid numero.');
    }
    if (typeof data.date !== 'string' || !/^\d{4}-\d{2}-\d{2}$/.test(data.date)) {
        errors.push('Invalid date.');
    }
    if (typeof data.montant !== 'string' || isNaN(Number(data.montant))) {
        errors.push('Invalid montant.');
    }
    if (typeof data.montantTtc !== 'string' || isNaN(Number(data.montantTtc))) {
        errors.push('Invalid montantTtc.');
    }

    if (errors.length > 0) {
        console.log('Mandat errors:', errors);
        return false;
    }
    return true;
}

type OperationsProps = {
    className?: string,
    operations: Operation[]
}

export const OperationsDisplay = (props: OperationsProps) => {
    return (
        <div className={props.className}>
            {
                props.operations.map(operation => <OperationDisplay operation={operation}/>)
            }
        </div>
    )
}

const ErrorsDisplay = ({errors}: { errors: { [key: string]: string[]; } }) => {
    if (Object.keys(errors).length === 0) {
        return null;
    }
    return (
        <ul className={'m-0'}>
            {
                Object.keys(errors).map(chapter =>
                    errors[chapter].map(error =>
                        <li className={'m-0'}>{`${chapter} : ${error}`}</li>
                    )
                )
            }
        </ul>
    )
}

type OperationDisplayProps = {
    operation: Operation
}

const OperationDisplay = ({operation}: OperationDisplayProps) => {

    // const result = validateOperation(operation.data);
    
    const getDisplayName = () => {
        if (isMoiOperation(operation)) { return operation.typeOperation }
        if (isBaseOperation(operation)) { return `${operation.codeAode}` }
        return 'Erreur'
    }

    return (
        <Alert variant={operation.isValid() ? 'success' : 'danger'} className={'my-1 mx-0 p-2'}>
            {`${getDisplayName()}`}
            <ErrorsDisplay errors={operation.errors}/>
        </Alert>
    )
}