/**
 * Récupère la valeur de l'attribut field de l'objet data
 * @example ( { date: { year: 2021 } }, 'date.year') => 2021
 * @param data Objet source
 * @param field Attribut cible, sous attributs séparés par des points
 * @param accessor
 */
export const getFieldValue = (data: any, field: string, accessor?: any) : any => {
    if (!data) { return data;}
    let result: any = null;
    if (accessor) {
        result = accessor(data);
        if (result !== undefined && result !== null && typeof result === 'string') {
            return result;
        }
    }
    const fields = field.split('.');
    result = data;
    fields.forEach(f => {
        if (result !== null && result !== undefined && typeof result !== 'string') {
            result = f in result ? result[f] : undefined
        }
    })
    return result;
}
export const isSet = (data: any) => {
    return data !== undefined && data !== null;
}