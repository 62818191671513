import {CrudDataList, CrudRepository} from ".";
import Keycloak from "keycloak-js";
import axios from "redaxios";
import {InternalDocument} from "../model";
import {isEmpty} from "../utils/control/isEmpty";

export class InternalDocumentRepository extends CrudRepository<InternalDocument> {
    constructor(keycloak: Keycloak | null = null) {
        super('internal_documents', "POST", keycloak)
    }

    protected jsonToEntity(json: any): InternalDocument | null {
        if (!json) return null;
        return {
            id: json.id,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            deletedAt: json.deletedAt,
            name: json.name,
            description: json.description,
            permission: json.permission,
            file: json.file,
            filename: json.filename,
            thumbnail: json.thumbnail,
            thumbnailFilename: json.thumbnailFilename,
            version: json.version,
            directory: json.directory,
        }
    }

    public show() : Promise<CrudDataList<InternalDocument>> {
        return new Promise<CrudDataList<InternalDocument>>((resolve, reject) => {
            axios({
                url:`${process.env.REACT_APP_SERVER_URL}/show/${this.entityCode}`,
                method: 'GET',
                headers: this.headers
            })
                .then((response : any) => {
                    resolve({
                        nbItems: response.data['hydra:totalItems'],
                        data: this.jsonToEntities(
                            response.data['hydra:member']
                        ),
                    });
                })
                .catch((error : any) => {
                    reject(error);
                })
        })
    }

    protected async entityToJson(entity: InternalDocument): Promise<any> {
        const formData = new FormData();

        const exclude = ['file', 'thumbnail']

        if (!isEmpty(entity.file)) {
            const blob = new Blob([entity.file], {type: entity.file.type });
            const filename = entity.file && entity.file.name.split('\\').pop();
            formData.append('file', blob, filename);
        }

        if (!isEmpty(entity.thumbnail)) {
            const blob = new Blob([entity.thumbnail], {type: entity.thumbnail.type });
            const filename = entity.thumbnail && entity.thumbnail.name.split('\\').pop();
            formData.append('thumbnail', blob, filename);
        }

        Object.keys(entity).forEach((key) => {
            if (!exclude.includes(key))
            {
                const value = entity.hasOwnProperty(key) ? (entity as any)[key] : undefined;
                if (value !== undefined && value !== null) {
                    switch (key) {
                        case 'directory':
                            formData.append(key, this.getIRI((entity as any)[key], 'internal_directories'))
                            break;
                        default:
                            formData.append(key, (entity as any)[key])
                            break;
                    }
                }
            }
        })

        return formData;
    }
}