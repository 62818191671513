import React from "react";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";
import {TileCategoryRepository, TileRepository} from "../../../repository";
import {Tile} from "../../../model";
import {TileStateRepository} from "../../../repository";

export const UpdateTile = () => {
    return <EntityFormTemplate<Tile>
        mode={'update'}
        repository={new TileRepository()}
        title={{
            page: `État`,
            schema: `Modifier la tuile "$0"`,
            params: [`name`]
        }}
        fields= {[
            { label: `Nom`, id: 'name', type: 'text', hint: 'Utiliser le caractère # pour forcer un saut de ligne.' },
            { label: `Description`, id: 'description', type: 'text' },
            { label: `État`, id: 'state', type: 'relation', repository: new TileStateRepository() },
            { label: `Catégorie`, id: 'category', type: 'relation', repository: new TileCategoryRepository() },
            { label: `Image`, id: 'imageFile', type: 'file' },
            { label: `URL cible`, id: 'url', type: 'text' },
            { label: `Permission`, id: 'permission', type: 'text' },
        ]}
    />
}