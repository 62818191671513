import React from "react";
import {Doc} from "../core/types";
import {Badge, Button} from "react-bootstrap";

type DocumentProps = {
    document: Doc
}

export const Document = ({document} : DocumentProps) => {

    const now = new Date().getTime();

    return (
        <div
            className={`border rounded m-2 p-2 border-primary d-flex flex-column flex-lg-row gap-5 text-center text-lg-start align-items-center align-items-lg-stretch`}
            style={{
                backgroundColor: 'rgba(255,255,255,.5)'
            }}
        >
            <img
                src={`${process.env.REACT_APP_CONFIG_URL}/thumbnail/${document.thumbnail}?${now}`}
                alt={'thumbnail'}
                className={`rounded border`}
                style={{
                    height: 200,
                    width: 150,
                    objectFit: 'contain'
                }}
            />
            <div
                className={`d-flex flex-column align-items-stretch justify-content-between w-100`}
            >
                <div>
                    <h2 className={`ff-netto text-primary text-decoration-underline`}>{document.title}</h2>
                    {
                        document.version &&
                            <Badge bg={"secondary"}>
                                {`Version : ${document.version}`}
                            </Badge>
                    }
                    {
                        document.description &&
                            <p className={'text-start'}>
                                {document.description}
                            </p>
                    }
                </div>
                <div className={`w-100 d-flex justify-content-center justify-content-lg-end`}>
                    <a href={`${process.env.REACT_APP_CONFIG_URL}/download/${document.file}`}>
                        <Button
                            variant={'outline-primary'}
                            style={{ right: '.5rem', bottom: '.5rem' }}
                        >
                            Télécharger le document
                        </Button>
                    </a>
                </div>

            </div>
        </div>
    )
}