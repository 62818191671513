import React, {CSSProperties} from "react";
import {Container, Tooltip} from 'react-bootstrap'
import {ReactComponent as Placeholder} from '../image/image.svg'
import {ComponentWithTooltip} from "./ComponentWithTooltip";
import {TileCategory, Tile} from "../model";

type GalleryProps = {
    categories: TileCategory[],
}

export const Gallery = (props: GalleryProps) => {

    return (
        <div style={{ minHeight: '100%', display: 'grid', gridTemplateRows: '5rem 1fr', width: '100%'}}>
            <div className={'text-center position-relative w-100'}>
                <div
                    className={`ff-netto bg-dark swatch-primary text-center m-0 fs-3 w-100`}
                    style={{
                        lineHeight: '5rem',
                        height: '5rem',
                        display: 'inline-block',
                        padding: '0 2rem',
                    }}
                >
                    {`Bienvenue sur le portail des services du Te61`}
                </div>
            </div>

            <Container className={'d-flex-center flex-column py-2'}>
                <div
                    className={'d-grid gap-4 w-100 '}
                    style={{
                        gridTemplateColumns: 'repeat(auto-fit, minmax(12rem, 1fr))',
                    }}
                >
                    { props.categories.map((category, index) => <GalleryChapter key={index} category={category!}/>) }
                </div>
            </Container>
        </div>
    )
}

type GalleryChapterProps = {
    category: TileCategory
}

const GalleryChapter = (props: GalleryChapterProps) => {
    return (
        <>
            <div
                className={`ff-titillium p-1 ps-2 rounded`}
                style={{
                    gridColumn: '1/-1',
                    backgroundColor: 'rgba(255,255,255,.5)'
                }}
            >
                <div className={`text-primary fs-4`}>{props.category.name}</div>
                {props.category.description && <div>{props.category.description}</div>}
            </div>
            {
                props.category.tiles.map((tile, index) => <GalleryTile key={index} index={index} tile={tile}/>)
            }
        </>
    );
}

type GalleryTileProps = {
    tile: Tile,
    index: number
}

export const GalleryTile = (props: GalleryTileProps) => {

    const tooltip = () => {

        const state = props.tile.state;

        if (state?.description || props.tile.description)
        {
            return <Tooltip>
                {
                    state?.description && <div className={`fw-bold`}>{state.description}</div>
                }
                <div>
                    {props.tile.description}
                </div>
            </Tooltip>
        }

        return null;
    }

    let style: CSSProperties = {};
    const state = props.tile.state;

    style.color = state?.color
    style.border = `1px solid ${state?.color}`

    const now = new Date().getTime();

    return (
        <ComponentWithTooltip tooltip={tooltip()}>
            {
                state?.disable
                    ? (
                        <div
                            className={`gallery-tile disabled rounded ff-netto`}
                            style={{ ...style, fontSize: '1.2rem' }}
                            key={props.index}
                        >
                            {
                                props.tile.imageName !== undefined ?
                                    <img
                                        alt={props.tile.name}
                                        src={`${process.env.REACT_APP_SERVER_URL}/images/tiles/${props.tile.imageName}?${now}`}
                                        style={{
                                            height: '50%',
                                            width: 'auto',
                                            maxWidth: '90%'
                                        }}
                                    />
                                    :
                                    <Placeholder
                                        style={{
                                            height: '50%',
                                            width: 'auto',
                                            maxWidth: '90%'
                                        }}
                                    />
                            }
                            <div>{props.tile.name.split('#').map(row => <div>{row.trim()}</div>)}</div>
                        </div>
                    )
                    : (
                        <a
                            href={props.tile.url}
                            className={`gallery-tile rounded ff-netto`}
                            style={{ ...style, fontSize: '1.2rem' }}
                            key={props.index}
                        >
                            {
                                props.tile.imageName !== undefined ?
                                    <img
                                        alt={props.tile.name}
                                        src={`${process.env.REACT_APP_SERVER_URL}/images/tiles/${props.tile.imageName}?${now}`}
                                        style={{
                                            height: '50%',
                                            width: 'auto',
                                            maxWidth: '90%'
                                        }}
                                    />
                                    :
                                    <Placeholder
                                        style={{
                                            height: '50%',
                                            width: 'auto',
                                            maxWidth: '90%'
                                        }}
                                    />
                            }
                            <div>{props.tile.name.split('#').map((row, index) => <div key={index}>{row.trim()}</div>)}</div>
                        </a>
                    )
            }

        </ComponentWithTooltip>
    )
}