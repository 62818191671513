import {Page} from "../../../component/Page";
import {Alert, Button, Container, Form} from "react-bootstrap";
import React, {useState} from "react";
import {BaseOperation, MoiOperation, Operation, OperationsDisplay} from "./Operation";
import {parseJson} from "../../../utils/jsonManipulation";
import jsonStatham from '../../../image/json-statham.jpg'
import flamme from "../../../image/flamme_min.png";

type Location = {
    col: number,
    line: number,
}

type Lines = {
    prev?: string,
    error: string,
    next?: string
}

export type Error = {
    message: string,
    location?: Location
    lines?: Lines
}

export type Data = {
    operations: Operation[],
    syntheseCaracteristiquesEconomiques: Synthesis
}

export type Synthesis = {}

type FaceProps = {}

export const Face = (props: FaceProps) => {

    const [filename, setFilename] = useState<string | undefined>()
    const [data, setData] = useState<Data | undefined>()
    const [errors, setErrors] = useState<Error[]>([])

    const buildLines = (lines?: Lines, location?: Location) => {
        if (!location && !lines) { return null }

        const fragments = []

        if (location) {
            fragments.push(<div>{`[ligne: ${location.line}, colonne: ${location.col}]`}</div>)
        }

        const col = location && location.col - 1;

        if (lines) {
            const elems = []
            if (lines.prev) { elems.push(lines.prev
                .replaceAll(' ', ' ')
                .replaceAll('\t', ' ')
            )}
            if (lines.error) {
                const line =
                    lines.error
                        .replaceAll(' ', ' ')
                        .replaceAll('\t', ' ')
                if (col !== undefined && col >= 0 && col <= line.length) {
                    const before = line.substring(0, col);
                    const targetChar = line.charAt(col);
                    const after = line.substring(col + 1);
                    elems.push(`<span class="error">${before}<b>${targetChar}</b>${after}</span>`)
                    let arrow = ""
                    for (let i =0; i < col; i++) { arrow += " " }
                    arrow += '↑'
                    elems.push(`<span class='error'><b>${arrow}</b></span>`)
                }
                else {
                    elems.push(line)
                }
            }
            if (lines.next) { elems.push(lines.next
                .replaceAll(' ', ' ')
                .replaceAll('\t', ' ')
            )}
            fragments.push(<div className={'ff-mono p-console p-2 m-2 rounded'}>{elems.map(elem => <div className={'m-0 p-0'} dangerouslySetInnerHTML={{__html: elem}}/>)}</div>)
        }

        return fragments
    }

    const onChange = async (e: any) => {
        if (e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files[0]
            const extension = file.name.split('.').pop()
            if (extension === "json") {
                const reader = new FileReader()
                reader.onload = async () => {
                    const text = reader.result as string
                    setData(control(text))
                    setFilename(file.name)
                }
                reader.readAsText(file)
            }
        } else
            setData(undefined)
    }

    const control = (text: string): Data | undefined => {
        try {
            const data = parseJson(text)

            if (!data.hasOwnProperty('operations')) {
                setErrors(errors => [
                    ...errors,
                    { message: "Aucune opération trouvée" }
                ])
                return undefined
            }

            if (!data.hasOwnProperty('syntheseCaracteristiquesEconomiques')) {
                setErrors(errors => [
                    ...errors,
                    { message: "Synthèse manquante" }
                ])
                return undefined
            }

            const result : Data = {
                operations: [],
                syntheseCaracteristiquesEconomiques: data.syntheseCaracteristiquesEconomiques
            }

            data.operations.forEach((operation: any) => result.operations.push(
                operation.typeOperation === "MOI" ? new MoiOperation(operation) : new BaseOperation(operation)
            ))

            return result;

        } catch (e: any) {
            setErrors([{...e, message: e.message}])
        }
        return undefined
    }

    if (errors.length > 0) {
        return (
            <Page>
                <Container>
                    <h2 className={'text-center'}>Une erreur est survenue</h2>
                    <div className={'mt-3'}>
                        {
                            errors.map((error, index) =>
                                <Alert key={index} variant={'danger'}>
                                    {error.message}
                                    {buildLines(error.lines, error.location)}
                                </Alert>)
                        }
                    </div>
                    <Button
                        onClick={() => {
                            setData(undefined)
                            setErrors([])
                        }}
                    >
                        Réinitialiser
                    </Button>
                </Container>
            </Page>
        )
    }

    if (!data) {
        return (
            <Page>
                <Container className={'d-flex-center flex-column w-100'}>
                    <h2 className={'text-center'}>Importer un fichier JSON</h2>
                    <Form className={'m-3 w-100'}>
                        <Form.Control
                            type={'file'}
                            onChange={onChange}
                            accept={".json"}
                        />
                    </Form>
                    <img
                        className={'w-25 rounded-3 opacity-75'}
                        src={jsonStatham}
                        alt={'json statham'}
                    />
                </Container>
            </Page>
        )
    }

    return (
        <Page>
            <Container>
                <h2 className={'text-center'}>{filename}</h2>
                <OperationsDisplay className={'mt-3'} operations={data.operations}/>
                <Button
                    onClick={() => {
                        setData(undefined)
                        setErrors([])
                    }}
                >
                    Réinitialiser
                </Button>
            </Container>
        </Page>
    )
}