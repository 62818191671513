import React from "react";
import {InternalDocumentRepository} from "../../../repository";
import {InternalDocument} from "../../../model";
import {EntityReadTemplate} from "../../../component/template/EntityReadTemplate";
import {Downloader} from "../../../component/Downloader";
import {Attribute} from "../../../component/Attribute";

export const ReadInternalDocument = () => {

    return (
        <EntityReadTemplate<InternalDocument>
            repository={new InternalDocumentRepository()}
            title={{
                page: `Document`,
                schema: `$0`,
                params: [`name`]
            }}
            attributes={[
                { label: `Nom`, key: 'name' },
                { label: `Description`, key: 'description' },
                { label: `Version`, key: 'version' },
                { label: `Répertoire`, key: 'directory.name' },
                { label: `Permission`, key: 'permission' },
            ]}
        >
            {
                (data: InternalDocument) => (
                    <Attribute label={'Fichier'} value={<Downloader id={data.id} type={'internal'} filename={data.name}/>}/>
                )
            }
        </EntityReadTemplate>
    )
}