import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import React from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useLocation, matchPath} from 'react-router-dom';
import {People} from '../image/svgAsElement'
import flamme from "../image/flamme_min.png"
import {Undo2} from "lucide-react"
import {AppRoute, AppRoutes, RoutesContainer} from "../core/routing/routes";

type NavigationProps = {
    type: 'default' | 'admin'
}

export const Navigation = (props: NavigationProps) => {

    const {keycloak, initialized} = useKeycloak();
    const {type} = props;

    const roles = (keycloak.tokenParsed?.resource_access?.portal as any)?.permission;

    const buildName = (data: any): string => {
        if (!data) return 'Erreur';
        const fragments = [];
        if (data.given_name) {
            fragments.push(data.given_name)
        }
        if (data.family_name) {
            fragments.push(data.family_name)
        }
        return fragments.length > 0 ? fragments.join(' ') : 'Inconnu';
    }

    const buildLogin = () => {
        if (!initialized || !keycloak) {
            return null;
        }
        if (!keycloak.authenticated) {
            return <Button>Connexion</Button>
        }

        const resource_access = keycloak.tokenParsed?.resource_access
        const permissions = resource_access && (resource_access[`${process.env.REACT_APP_KEYCLOAK_CLIENTID}`] as any)?.permission

        return (
            <NavDropdown id={'login-dropdown'} title={
                <span style={{fontSize: '1rem', lineHeight: '1rem'}}>
                    <People className={'text-dark'} size={'1.3rem'}/>
                    <span className={'ms-2'}>{`${buildName(keycloak.tokenParsed)}`}</span>
                </span>
            }>
                <NavDropdown.Item key={`profile`}
                                  onClick={() => keycloak.accountManagement()}>{`Profil`}</NavDropdown.Item>
                {
                    permissions && permissions.includes('admin')
                        ? <NavDropdown.Item key={`admin`} href={'/admin/tiles'}>{`Administration`}</NavDropdown.Item>
                        : null
                }
                <NavDropdown.Item key={`logout`} onClick={() => keycloak.logout()}>
                    {`Déconnexion`}
                </NavDropdown.Item>
            </NavDropdown>
        )
    }

    const location = useLocation();
    const path = location.pathname;

    const isActive = (route: string | string[]): boolean => {
        let matched: any = null;
        if (Array.isArray(route)) {
            return route.reduce((acc, item) => {
                    if (acc) {
                        return acc;
                    }
                    return isActive(item);
                },
                false
            )
        }
        matched = matched ? matched : matchPath(`${route}`, path)

        if (matched) { return true; }

        matched = matched ? matched : matchPath(`${route}/:id`, path)
        matched = matched ? matched : matchPath(`${route}/:id/edit`, path)
        matched = matched ? matched : matchPath(`${route}/:id/delete`, path)

        if (matched) {
            const id = matched.params.id;
            return !isNaN(id);
        }

        return false;
    }

    const buildNavLinks = (item: AppRoute | RoutesContainer, dropdown?: boolean): JSX.Element | null | (JSX.Element | null)[] => {
        if ((props.type === "admin") !== (item.isAdmin === true)) {
            return null;
        }
        if (item.type === 'route') {
            if (item.permission && !roles.includes(item.permission)) {
                return null;
            }
            if (!item.isHidden) {
                if (dropdown) {
                    return <NavDropdown.Item key={item.path} href={item.path} active={isActive(item.path)}>
                        {item.label}
                    </NavDropdown.Item>
                }
                return <Nav.Link key={item.path} href={item.path} active={isActive(item.path)}>{item.label}</Nav.Link>
            }
            return null;
        }

        if (item.permission && !roles.includes(item.permission)) {
            return null;
        }

        return (
            <NavDropdown title={item.label} id={item.label} active={isActive(item.routes.map(route => route.path))}>
                {
                    item.routes.map(route => buildNavLinks(route, true)).flat()
                }
            </NavDropdown>
        )
    }

    const adminReturn = () => {
        if (type === 'admin') {
            return (
                <Nav.Link key={`home`} as={Button} variant={'light'} href="/" active={path === '/'}>
                    <span style={{fontSize: '1rem', lineHeight: '1rem'}}>
                        <Undo2 className={'text-dark me-2'} size={'1.3rem'}/>
                        <span>Quitter l'administration</span>
                    </span>
                </Nav.Link>
            )
        }
        return null;
    }

    const routes = [
        adminReturn(),
        ...AppRoutes.map(route => buildNavLinks(route)).flat()
    ]
        .flat()
        .filter(elem => elem !== null)

    return (
        <Navbar bg={type === 'admin' ? 'warning' : 'light'} expand={'md'}>
            <Container>
                <Navbar.Toggle/>
                <Navbar.Collapse className="justify-content-between">
                    <Nav className="me-auto">
                        {routes}
                    </Nav>
                    {buildLogin()}
                </Navbar.Collapse>
            </Container>
            <img
                className={'position-absolute logo-flamme'}
                src={flamme}
                alt={'flamme Te61'}
                style={{
                    zIndex: 99
                }}
            />
        </Navbar>
    )
}