import React from "react";
import {TileCategoryRepository} from "../../../repository";
import {TileCategory} from "../../../model";
import {ColumnDef} from "@tanstack/react-table";
import { DateCell} from "../../../component/list/cell";
import {EntityListTemplate} from "../../../component/template/EntityListTemplate";

export const ListTileCategory = () => {

    const columns: ColumnDef<TileCategory>[] = [
        {
            header: "Nom",
            accessorKey: 'name',
            id: 'name',
        },
        {
            header: 'Description',
            accessorKey: 'description',
            id: 'description',
        },
        {
            header: 'Nombre de tuiles',
            accessorKey: 'numberOfTiles',
            id: 'numberOfTiles',
        },
        {
            header: `Dernière mise à jour`,
            accessorKey: `updatedAt`,
            id: `updatedAt`,
            cell: DateCell
        }
    ]

    return (
        <EntityListTemplate<TileCategory>
            repository={new TileCategoryRepository()}
            title={`Catégorie`}
            columns={columns}
            rearrangeable
        />
    )
}