import {useEffect, useState} from "react";
import {CrudDataList} from "../../repository";
import {Entity} from "../../model";

export const useFetchAll =
    <E extends Entity>(fetchFunction: () => Promise<CrudDataList<E>>) :
        [ loading: boolean, data: E[], error: string | null ] => {
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<E[]>([])
    const [error, setError] = useState<string|null>(null)

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true)
        fetchFunction()
            .then(result => {
                if (result && result.data) {
                    setData(result.data)
                }
            })
            .catch(error => setError(error.message))
            .finally(() => setLoading(false))
        return () => controller.abort();
    }, [fetchFunction])

    return [ loading, data, error ];
}

export const useFetchOne =
    <E extends Entity>(fetchFunction: () => Promise<E | null>) :
        [ loading: boolean, data: E | null, error: string | null ] => {
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<E|null>(null)
    const [error, setError] = useState<string|null>(null)

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true)
        fetchFunction()
            .then(result => {
                if (result) {
                    setData(result)
                }
            })
            .catch(error => setError(error.message))
            .finally(() => setLoading(false))
        return () => controller.abort();
    }, [fetchFunction])

    return [ loading, data, error ];
}