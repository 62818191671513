import React, {useMemo, useState} from "react";
import {Page} from "../component/Page";
import {Gallery} from "../component/Gallery";
import {useLocation} from 'react-router-dom'
import {Alert, Container, Toast} from "react-bootstrap";
import {FEEDBACK} from "../core/routing/feedback";
import {useKeycloak} from "@react-keycloak/web";
import {TileRepository} from "../repository";
import {Tile, TileCategory} from "../model";
import {useFetchAll} from "../utils/hook/useFetch";
import {Loading} from "../component/Loading/Loading";

export const Home = () => {

    const location = useLocation();
    const {keycloak} = useKeycloak();

    const status = location.state ? location.state.status : null;

    const [visible, setVisible] = useState<boolean>(status !== null);

    const repository = useMemo(() => new TileRepository(keycloak), [keycloak]);

    const [loading, data, error] = useFetchAll<Tile>(repository.showTiles)

    const prepareTiles = (tiles: Tile[] | null) => {
        if (!tiles) {
            return [];
        }

        const categories: { [key: number]: TileCategory } = {};

        tiles.forEach(tile => {
            if (Object.keys(categories).includes(`${tile.category?.id}`)) {
                categories[tile.category!.id].tiles.push(tile)
            } else {
                if (tile.category !== undefined && tile.category !== null) {
                    categories[tile.category!.id] = {...tile.category, tiles: [tile]}
                }
            }
        })

        return categories;
    }

    const FeedBack = () => {

        if (!status) return null;

        const feedback = FEEDBACK[status];

        return (
            <Toast
                onClose={() => {
                    setVisible(false)
                }}
                show={visible}
                bg={feedback.variant}
                className={'position-absolute'}
                style={{
                    top: 'calc(60px + 5rem)', // Navigation + title
                    right: 5,
                    width: '50%'
                }}
                delay={4000}
                autohide
            >
                <Toast.Header className={`justify-content-between text-${feedback.variant}`}>
                    <div className={'d-flex m-1 fw-bold ff-titillium align-items-center'}>
                        {
                            feedback.icon ?? null
                        }
                        <span className={'ms-2'} style={{fontSize: '1.1rem'}}>{`${feedback.title}`}</span>
                    </div>
                </Toast.Header>
                <Toast.Body className={`swatch-${feedback.variant} m-2`}>
                    <div className={'d-flex'} style={{fontSize: '1.1rem'}}>
                        {`${feedback.message}`}
                    </div>
                </Toast.Body>
            </Toast>
        )
    }

    let preparedData: {[p: number]: TileCategory} = {};

    if (loading) {
        return (
            <Page>
                <Container className={'d-flex-center flex-column'} style={{minHeight: '100%'}}>
                    <Loading/>
                </Container>
            </Page>

        )
    }

    if (error) {
        return (
            <Page>
                <Container className={'d-flex-center flex-column'} style={{ minHeight: '100%' }}>
                    <Alert variant={'danger'}>
                        {error}
                    </Alert>
                </Container>
            </Page>
        )
    }

    if (data) {
        preparedData = prepareTiles(data)
    }

    if (Object.keys(preparedData).length === 0) {
        return (
            <Page>
                <Container className={'d-flex-center flex-column'} style={{ minHeight: '100%' }}>
                    <Alert variant={'warning'}>
                        {`Vous n'avez accès à aucun service du Territoire d'Énergie Orne pour le moment.`}
                    </Alert>
                </Container>
            </Page>
        )
    }

    return (
        <Page>
            <Gallery
                categories={Object.values(preparedData)}
            />
            <FeedBack/>
        </Page>
    )
}
