import React from "react";
import {TileCategoryRepository} from "../../../repository";
import {TileCategory} from "../../../model";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";

export const UpdateTileCategory = () => {

    return (
        <EntityFormTemplate<TileCategory>
            mode={'update'}
            repository={new TileCategoryRepository()}
            title={{
                page: `Catégorie`,
                schema: `Modifier la catégorie "$0"`,
                params: [`name`]
            }}
            fields= {[
                { label: `Nom`, id: 'name', type: 'text' },
                { label: `Description`, id: 'description', type: 'text' },
            ]}
        />
    )
}