import {Tile} from "../model";
import {CrudDataList, CrudRepository} from ".";
import Keycloak from "keycloak-js";
import axios from "redaxios";
import {isEmpty} from "../utils/control/isEmpty";

export class TileRepository extends CrudRepository<Tile> {
    constructor(keycloak: Keycloak | null = null) {
        super('tiles', "POST", keycloak)
        this.showTiles = this.showTiles.bind(this)
    }

    protected jsonToEntity(json: any): Tile | null {
        if (!json) return null;
        return {
            id: json.id,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            deletedAt: json.deletedAt,
            name: json.name,
            description: json.description,
            imageFile: json.imageFile,
            imageName: json.imageName,
            url: json.url,
            permission: json.permission,
            state: json.state,
            category: json.category,
        }
    }

    public showTiles(fields ?: string) : Promise<CrudDataList<Tile>> {
        return new Promise<CrudDataList<Tile>>((resolve, reject) => {
            axios({
                url:`${process.env.REACT_APP_SERVER_URL}/show_tiles`,
                method: 'GET',
                headers: this.headers
            })
                .then((response : any) => {
                    resolve({
                        data: this.jsonToEntities(response.data['hydra:member']),
                        nbItems: response.data['hydra:totalItems'],
                    });
                })
                .catch((error : any) => {
                    reject(error);
                })
        })
    }

    protected async entityToJson(entity: Tile): Promise<any> {
        const formData = new FormData();

        if (!isEmpty(entity.imageFile)) {
            const blob = new Blob([entity.imageFile], {type: entity.imageFile.type });
            const filename = entity.imageFile.name && entity.imageFile.name.split('\\').pop();
            formData.append('imageFile', blob, filename);
        }

        Object.keys(entity).forEach((key) => {
            if (key !== 'imageFile' && key !== 'imageName' && key !== 'imageFile_file')
            {
                const value = entity.hasOwnProperty(key) ? (entity as any)[key] : undefined;
                if (value !== undefined && value !== null) {
                    switch (key) {
                        case 'state':
                            formData.append(key, this.getIRI((entity as any)[key], 'tile_states'))
                            break;
                        case 'category':
                            formData.append(key, this.getIRI((entity as any)[key], 'tile_categories'))
                            break;
                        default:
                            formData.append(key, (entity as any)[key])
                            break;
                    }
                }
            }
        })



        return formData;
    }
}