import React from "react";
import {Badge} from "react-bootstrap";
import {Document} from "../model";
import {Downloader} from "./Downloader";

type DocumentDisplayProps = {
    document: Document,
    entityCode: string | null
}

export const DocumentDisplay = ({document, entityCode} : DocumentDisplayProps) => {

    const now = new Date().getTime();

    return (
        <div
            className={`border rounded m-2 p-2 border-dark d-flex flex-column flex-lg-row gap-5 text-center text-lg-start align-items-center align-items-lg-stretch`}
            style={{
                backgroundColor: 'rgba(255,255,255,.5)'
            }}
        >
            <img
                src={`${process.env.REACT_APP_SERVER_URL}/images/thumbnails/${document.thumbnailFilename}?${now}`}
                alt={'thumbnail'}
                className={`rounded border border-dark-subtle`}
                style={{
                    height: 200,
                    width: 150,
                    objectFit: 'contain'
                }}
            />
            <div
                className={`d-flex flex-column align-items-stretch justify-content-between w-100`}
            >
                <div>
                    <h2 className={`ff-netto text-primary text-decoration-underline`}>{document.name}</h2>
                    {
                        document.version &&
                            <Badge bg={"secondary"}>
                                {`Version : ${document.version}`}
                            </Badge>
                    }
                    {
                        document.description &&
                            <p className={'text-start'}>
                                {document.description}
                            </p>
                    }
                </div>
                <div className={`w-100 d-flex justify-content-center justify-content-lg-end`}>
                    <Downloader
                        id={document.id}
                        type={entityCode || ''}
                        text={`Télécharger le document`}
                    />
                </div>

            </div>
        </div>
    )
}