import React from "react";
import {TileCategoryRepository} from "../../../repository";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";
import {TileCategory} from "../../../model";

export const CreateTileCategory = () => {

    return (
        <EntityFormTemplate<TileCategory>
            mode={'create'}
            repository={new TileCategoryRepository()}
            title={{
                page: `Catégorie`,
                schema: `Ajouter une catégorie`
            }}
            fields= {[
                { label: `Nom`, id: 'name', type: 'text' },
                { label: `Description`, id: 'description', type: 'text' },
            ]}
        />
    )
}