import React, {useMemo} from "react";
import {Page} from "../component/Page";
import {Accordion, Alert, Badge, Container} from "react-bootstrap";
import {Loading} from "../component/Loading/Loading";
import {useFetchAll} from "../utils/hook/useFetch";
import {TemplateDirectoryRepository} from "../repository";
import {useKeycloak} from "@react-keycloak/web";
import {TemplateDirectory} from "../model";
import {TemplateFileDisplay} from "../component/TemplateFileDisplay";
import {File} from "lucide-react";

export const Template = () => {

    const {keycloak} = useKeycloak();

    const repository = useMemo(() => new TemplateDirectoryRepository(keycloak), [keycloak]);

    const [loading, data, error] = useFetchAll<TemplateDirectory>(repository.show)

    const buildContent = () => {
        const filledDirectories = data
            .filter(d => d.filteredTemplateFiles.length > 0)

        if (filledDirectories.length === 0) {
            return (
                <Container>
                    <Alert variant={'warning'}>
                        Aucun modèle disponible actuellement.
                    </Alert>
                </Container>
            )
        }

        return (
            <Container>
                <Accordion className={'py-4'}>
                    {
                        filledDirectories
                            .map((d, index) =>
                                <Accordion.Item eventKey={`${d.id}`}>
                                    <Accordion.Header>
                                        <div className={'d-flex justify-content-between w-100 pe-5'}>
                                            <div>
                                                <File/>
                                                {` ${d.name}`}
                                            </div>
                                            <Badge bg={"dark"}>
                                                {`${d.filteredTemplateFiles.length} document${d.filteredTemplateFiles.length > 1 ? 's' : ''}`}
                                            </Badge>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            d.filteredTemplateFiles.map((f, index) =>
                                                <TemplateFileDisplay key={index} file={{...f}}/>
                                            )
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                    }
                </Accordion>
            </Container>
        )
    }

    if (loading) {
        return (
            <Page>
                <Container className={'d-flex-center flex-column'} style={{minHeight: '100%'}}>
                    <Loading/>
                </Container>
            </Page>

        )
    }

    if (error) {
        return (
            <Page>
                <Container className={'d-flex-center flex-column'} style={{ minHeight: '100%' }}>
                    <Alert variant={'danger'}>
                        {error}
                    </Alert>
                </Container>
            </Page>
        )
    }

    return (
        <Page>
            <div className={'text-center position-relative w-100'}>
                <div
                    className={`ff-netto bg-dark swatch-primary text-center m-0 fs-3 w-100`}
                    style={{
                        lineHeight: '5rem',
                        height: '5rem',
                        display: 'inline-block',
                        padding: '0 2rem',
                    }}
                >
                    {`Modèles de fichiers`}
                </div>
            </div>
            {
                buildContent()
            }
        </Page>
    )
}