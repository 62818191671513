import React from "react";
import {useParams, Navigate} from "react-router-dom";
import {FEEDBACK} from "./feedback";

export const HandleStatus = () => {

    const params = useParams();

    let status = params.status ?? undefined;

    if (status !== undefined && status !== null) {
        if (!FEEDBACK.hasOwnProperty(status)) {
            status = 'NOT_FOUND'
        }
    }

    return (
        <Navigate
            to={'/'}
            state={{
                status: status,
            }}
            replace={true}
        />
    )
}
