import {Exclamation, XCircle} from "../../image/svgAsElement";
import React from "react";

export type Feedback = {
    title: string,
    message: string,
    variant: string,
    icon?: JSX.Element,
}

export const FEEDBACK : { [ key: string ] : Feedback } = {
    ERROR: { title: `Une erreur est survenue`, message: `Erreur inconnue.`, variant: 'danger', icon: <XCircle size={'1.5rem'}/>},
    ACCESS_DENIED: { title: `Accès interdit`, message: `L'accès à ce service est interdit.`, variant: 'danger', icon: <XCircle size={'1.5rem'}/>},
    ACCESS_FORBIDDEN: { title: `Accès non autorisé`, message: `Vous n'avez pas l'autorisation d'accéder à ce service.`, variant: 'danger', icon: <XCircle size={'1.5rem'}/>},
    SERVICE_OFFLINE: { title: `Service hors-ligne`, message: `Ce service est actuellement hors-ligne.`, variant: 'warning', icon: <Exclamation size={'1.5rem'}/>},
    NOT_FOUND: { title: `Service introuvable`, message: `Ce service n'existe pas.`, variant: 'warning', icon: <Exclamation size={'1.5rem'}/>},
}