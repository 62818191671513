import React from "react";
import {InternalDocumentRepository, InternalDirectoryRepository} from "../../../repository";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";
import * as Yup from "yup";
import {InternalDocument} from "../../../model";

export const CreateInternalDocument = () => {

    return (
        <EntityFormTemplate<InternalDocument>
            mode={'create'}
            repository={new InternalDocumentRepository()}
            title={{
                page: `Document interne`,
                schema: `Ajouter un document interne`
            }}
            fields={[
                {label: `Nom`, id: 'name', type: 'text'},
                {label: `Description`, id: 'description', type: 'text'},
                {label: `Permission`, id: 'permission', type: 'text'},
                {label: `Version`, id: 'version', type: 'text'},
                {label: `Répertoire`, id: 'directory', type: 'relation', repository: new InternalDirectoryRepository()},
                {label: `Fichier`, id: 'file', type: 'file', hint:`(taille < 5Mo)`},
                {label: `Miniature`, id: 'thumbnail', type: 'file', hint:`(taille < 5Mo)`},
            ]}
            validationSchema={
                Yup.object().shape({
                    name: Yup.string()
                        .min(3, 'Minimum 3 caractères.')
                        .max(200, 'Maximum 200 caractères.')
                        .required('Obligatoire'),
                    permission: Yup.string()
                        .permission("Doit être composé de lettres minuscules et de tirets."),
                    version: Yup.string()
                        .version("Format x.y attendu.")
                        .required('Obligatoire'),
                    directory: Yup.mixed()
                        .relation("InternalDirectory", "Répertoire invalide.")
                        .required('Obligatoire'),
                    file: Yup.mixed()
                        .fileType("document", "Type de fichier non supporté.")
                        .fileSize(5000, "Fichier trop volumineux."),
                    thumbnail: Yup.mixed()
                        .fileType("image", "Type de fichier non supporté.")
                        .fileSize(5000, "Fichier trop volumineux."),
                })
            }
        />
    )
}