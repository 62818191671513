import {TileCategory} from "../model";
import {CrudRepository} from ".";
import Keycloak from "keycloak-js";

export class TileCategoryRepository extends CrudRepository<TileCategory> {
    constructor(keycloak: Keycloak | null = null) {
        super('tile_categories', 'PATCH', keycloak)
        this.contentTypes["UPDATE"] = "application/merge-patch+json"
    }

    protected jsonToEntity(json: any): TileCategory | null {
        if (!json) return null;
        return {
            id: json.id,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            deletedAt: json.deletedAt,
            name: json.name,
            description: json.description,
            tiles: json.tiles,
            numberOfTiles: json.numberOfTiles,
        }
    }

    protected entityToJson(entity: TileCategory): any {
        return super.entityToJson(entity);
    }
}