import axios from "axios";
import {Button} from "react-bootstrap";
import {saveAs} from "file-saver";
import {useState} from "react";

type DownloaderProps = {
    id: number,
    type: "template" | 'internal' | string,
    filename?: string,
    text?: string
}

export const Downloader = (props: DownloaderProps) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    let url = `${process.env.REACT_APP_SERVER_URL}`
    switch (props.type) {
        case "template":
            url += `/download_template_file`;
            break;
        case "internal":
            url += `/download_internal_document`;
            break;
        default:
            url += `/download_${props.type}`;
            break;
    }

    const handleDownload = () => {
        setLoading(true);
        axios
            .get(
                `${url}/${props.id}`,
                {
                    method: "GET",
                    responseType: 'blob',
                }
            )
            .then((response) => {
                const {data, headers} = response;
                let filename = props.filename;
                if (!filename) {
                    const contentDisposition = headers[`content-disposition`];
                    filename = 'fichier_télechargé'
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        filename = match ? decodeURIComponent(match[1]) : filename;
                    }
                }
                saveAs(data, filename)
            })
            .catch(error => {
                setError(error?.response?.status || 'Erreur')
            })
            .finally(
                () => setLoading(false)
            )
    }

    return (
        <Button
            onClick={handleDownload}
            disabled={loading || error !== ''}
        >
            {
                error
                    ? `Erreur [HTTP ${error}]`
                    : props.text || `Télécharger`
            }
        </Button>
    )
}