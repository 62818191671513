import React, {useCallback, useEffect} from 'react'

import { useKeycloak } from '@react-keycloak/web'
import { useNavigate } from "react-router-dom";

type RequireAuthProps = {
    children: string | number | React.JSX.Element | null
    permission?: string
}

export const RequireAuth = (props: RequireAuthProps) : React.JSX.Element | null => {

    const { children, permission } = props;
    const { keycloak } = useKeycloak()
    const navigate = useNavigate()

    useEffect(() => {
        const resource_access = keycloak.tokenParsed?.resource_access
        const permissions = resource_access && (resource_access[`${process.env.REACT_APP_KEYCLOAK_CLIENTID}`] as any)?.permission
        if (props.permission && !permissions.includes(props.permission))
        {
            navigate('/ACCESS_FORBIDDEN');
        }
    }, [keycloak, permission, navigate, props.permission])

    const login = useCallback(() => {
        keycloak?.login()
    }, [keycloak])

    if (keycloak?.authenticated) {
        return (
            <>
                {children}
            </>
        );
    }
    else {
        login();
        return null;
    }
}