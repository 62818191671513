/**
 * Retourne noir ou blanc selon la couleur en paramètre pour maximiser le contraste
 * @param hexColor Couleur de fond
 */
export const blackOrWhite = (hexColor : string) : '#000000' | '#FFFFFF' => {
    const { r, g, b } = hexToRgb(hexColor) || { r: 0, g: 0, b: 0 };
    return (r*0.299 + g*0.587 + b*0.114) > 186 ? '#000000' : '#FFFFFF';
}

export function hexToRgb(hex : string) : {r: number, g: number, b: number} | null {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function componentToHex(c : number) : string {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r : number, g : number, b : number) : string {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const isHexa = (color?: string) => {
    if (!color) return false;
    return RegExp('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$').test(color);
}