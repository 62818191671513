import {Form} from "react-bootstrap";
import {Field, useField} from "formik";
import {FormFieldProps} from "./FormField";
import {useEffect, useState} from "react";
import {Entity} from "../../model";

export const RelationFormField = (props: FormFieldProps) => {

    const [field] = useField(props.id);
    const [options, setOptions] = useState<Entity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const repository = props.repository;
    if (repository !== undefined) {
        repository.setKeycloak(props.keycloak || null)
    }

    useEffect(() => {
        if (!repository) return;
        repository.readAll()
            .then(({data}) => {
                setOptions(data || [])
            })
            .finally(() => setLoading(false))
    }, [repository])

    const buildOptions = () => {
        return [
            <option value={''}>Choisissez...</option>,
            ...options.map(option => <option value={option.id}>{(option as any).name || option.id}</option>)
        ]
    }

    return (
        <>
            <Form.Label htmlFor={props.id}>
                {props.label}
            </Form.Label>
            {
                <Field
                    {...field}
                    as={Form.Select}
                    type={props.type}
                    id={props.id}
                    name={props.id}
                    disabled={loading}
                    value={field.value && field.value.id}
                    isInvalid={props.touched && !!props.error}
                    isValid={props.touched && !props.error}
                >
                    {buildOptions()}
                </Field>
            }
        </>
    )
}