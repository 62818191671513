import React from "react";
import {TemplateDirectoryRepository} from "../../../repository";
import {TemplateDirectory} from "../../../model";
import {EntityReadTemplate} from "../../../component/template/EntityReadTemplate";

export const ReadTemplateDirectory = () => {

    return (
        <EntityReadTemplate<TemplateDirectory>
            repository={new TemplateDirectoryRepository()}
            title={{
                page: `Document`,
                schema: `$0`,
                params: [`name`]
            }}
            attributes={[
                { label: `Nom`, key: 'name' },
                { label: `Permission`, key: 'permission' },
                { label: `Nombre de fichiers`, key: 'numberOfFiles' },
            ]}
        />
    )
}