import {Entity} from "../../model";
import {Alert, Button, Container, Offcanvas} from "react-bootstrap";
import React, {useCallback, useMemo, useState} from "react";
import {Item} from "../../types/Item";
import {CrudRepository} from "../../repository";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Loading} from "../Loading/Loading";
import {Page} from "../Page";
import {Form, Formik, FormikValues} from "formik";
import {FormField, FormFieldProps} from "../form/FormField";
import {FormSubmitButton} from "../form/FormSubmitButton";
import {useKeycloak} from "@react-keycloak/web";
import {useFetchOne} from "../../utils/hook/useFetch";
import {FormHelper} from "../../utils/FormHelper";
import {ObjectSchema} from "yup";
import {getFieldValue} from "../../utils/objectManipulation";

type EntityFormTemplateProps<E extends Entity> = {
    repository: CrudRepository<E>
    /**
     * Title of the page
     */
    title: { page: string, schema: string; params?: string[] },
    /**
     * Attributes of the Entity to display
     */
    fields: FormFieldProps[],
    validationSchema?: ObjectSchema<any>,
    /**
     * Add custom elements
     */
    children?: ((data: E) => Item),
    formData?: boolean,
    mode?: 'update' | 'create'
}

/**
 * Display Entity attributes
 */
export const EntityFormTemplate = <E extends Entity>(props: EntityFormTemplateProps<E>) => {

    const formHelper = new FormHelper();

    const [submitError, setSubmitError] = useState<string | undefined>(undefined);

    const [show, setShow] = useState(false);

    const {id} = useParams<{ id: string }>();

    const navigate = useNavigate();
    const {pathname} = useLocation()
    const {keycloak} = useKeycloak();

    const repository = useMemo(
        () => props.repository,
        [props.repository]
    );

    repository.setKeycloak(keycloak)

    const fetchFunction = useCallback(() => {
        if (!id) { return new Promise<null>((resolve) => { resolve(null) } ); }
        return repository.read(`${id}`)
    }, [id, repository])

    const [loading, data, error] = useFetchOne(fetchFunction)

    const buildTitle = (): string => {
        if (props.mode === "update") {
            if (!data) { return "Chargement..."; }
            if (!loading && error) { return "Impossible de récupérer l'élément"; }
        }

        if (props.title.params !== undefined) {
            for (let i = 0; i < props.title.params.length; i++) {
                let paramsValue: any = data;
                const fields = props.title.params[i].split('.');
                for (let field of fields) {
                    const fieldElems = field.split(':');
                    field = fieldElems[0];
                    paramsValue = paramsValue?.hasOwnProperty(field) ? paramsValue[field] : undefined;
                    // Si le paramètre est suivi d'un 'filtre' (ex: dateEnd:year) on applique un traitement
                    if (fieldElems.length > 1) {
                        switch (fieldElems[1]) {
                            case 'year':
                                paramsValue = new Date(paramsValue)?.toLocaleDateString(
                                    `${process.env.REACT_APP_LOCALE}`,
                                    {
                                        year: 'numeric'
                                    }
                                );
                                break;

                            case 'month':
                                paramsValue = new Date(paramsValue)?.toLocaleDateString(
                                    `${process.env.REACT_APP_LOCALE}`,
                                    {
                                        month: 'long',
                                        year: 'numeric'
                                    }
                                );
                                break;

                            case 'day':
                                paramsValue = new Date(paramsValue)?.toLocaleDateString(
                                    `${process.env.REACT_APP_LOCALE}`,
                                    {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric'
                                    }
                                );
                                break;

                            default:
                                break;
                        }
                    }
                }

                props.title.schema = props.title.schema.replace(
                    `$${i}`,
                    paramsValue !== undefined ? paramsValue : '😕'
                );
            }
        }
        return props.title.schema;
    };

    const handleSubmit = (values: FormikValues) => {
        repository.setKeycloak(keycloak)
        if (props.mode === 'create') {
            repository.create(values as E)
                .then((entity) => {
                    navigate(pathname.replace('create', `${entity?.id}`), {replace: true})
                })
                .catch(({status, message}) => {
                    setSubmitError(`[${status}] ${message}`)
                })
        } else {
            repository.update(values as E)
                .then(() => {
                    navigate(pathname.replace('/edit', ''))
                })
                .catch(({status, message}) => {
                    setSubmitError(`[${status}] ${message}`)
                })
        }
    }

    const buildDebug = (values: any, errors: any) => {
        if (process.env.REACT_APP_ENV !== 'dev') { return null; }
        return (
            <>
                <Button variant={"warning"} onClick={() => setShow(true)}>
                    Debug
                </Button>
                <Offcanvas show={show} onHide={() => setShow(false)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Données & erreurs</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <pre>
                            {JSON.stringify(values, null, 2)}
                        </pre>
                        <code>
                            <pre>
                                {JSON.stringify(errors, null, 2)}
                            </pre>
                        </code>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    }

    const buildContent = () => {
        if (loading) {
            return (
                <div className={'d-flex-center'}>
                    <Loading/>
                </div>
            )
        }

        if (error) {
            return (
                <Alert variant={'danger'}>
                    {error}
                </Alert>
            )
        }

        if (submitError) {
            return (
                <Alert variant={'danger'}>
                    {submitError}
                </Alert>
            )
        }

        const initialValues: any = props.mode === 'create' ? {} : {id: data?.id}
        props.fields.forEach((field) => {
            if (props.mode === 'create') {
                initialValues[field.id] = '';
            } else {
                initialValues[field.id] = formHelper.cleanInitialValue(data && (data as any)[field.id])
            }
        })

        return (
            <Container>
                <div className={'text-center my-3'}>
                    <h2>
                        {buildTitle()}
                    </h2>
                </div>
                <div className={'my-3'}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={props.validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            ({
                                 isValid,
                                 isSubmitting,
                                 values,
                                 errors,
                                 touched
                             }) => (
                                <Form>
                                    {
                                        props.fields.map((fieldProps) => (
                                            <FormField
                                                key={fieldProps.id}
                                                keycloak={keycloak}
                                                error={getFieldValue(errors, fieldProps.id)}
                                                touched={getFieldValue(touched, fieldProps.id)}
                                                {...fieldProps}
                                            />
                                        ))
                                    }
                                    <FormSubmitButton isValid={isValid} isSubmitting={isSubmitting}/>
                                    {buildDebug(values, errors)}
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            </Container>
        )
    }

    return (
        <Page type={"admin"} allowPrev={!loading}>
            <div className={'text-center position-relative w-100'}>
                <div
                    className={`ff-netto bg-dark swatch-primary text-center m-0 fs-3 w-100`}
                    style={{
                        lineHeight: '5rem',
                        height: '5rem',
                        display: 'inline-block',
                        padding: '0 2rem',
                    }}
                >
                    {`Administration • ${props.title.page}`}
                </div>
            </div>
            <div>
                {buildContent()}
            </div>
        </Page>
    )
}