import React from "react";
import {IconProps} from "./index";

export const People = (props: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? props.width ?? 16}
            height={props.size ?? props.height ?? 16}
            fill={props.fill ?? "currentColor"}
            className={props.className ?? ''}
            viewBox="0 0 16 16"
        >
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
        </svg>
    )
}