import React from "react";
import {Link} from "react-router-dom";
import {Undefined} from "./Undefined";
import {TooltipText} from "./TooltipText";
import {FieldType} from "../core/types/fieldType";
import {LinkDef} from "../types/Attribute";
import {isValue} from "../utils/control/isValue";

type AttributeProps = {
    label?: string,
    value?: number | string | JSX.Element,
    variant?: 'default' | 'sm' | 'xs',
    to?: LinkDef,
    tooltip?: string,
    highlight?: boolean,
    type?: FieldType
}

export const Attribute = ( { label, value, variant, to, tooltip, highlight, type } : AttributeProps ) => {

    const labelStyle : any = {};
    const valueStyle : any = {};
    let size = 25;

    switch (variant) {
        case 'xs':labelStyle.gridColumn = 'span 1';
            valueStyle.gridColumn = 'span 1';
            size = 10;
            break;
        case 'sm':
            labelStyle.gridColumn = 'span 1';
            valueStyle.gridColumn = 'span 2';
            valueStyle.overflow = 'hidden';
            size = 10;
            break;
        default:
        case 'default':
            break;
    }

    const makeValue = (value?: number | string | JSX.Element, to?: LinkDef) => {
        if (!isValue(value)) {
            return <div className={'fw-normal'} style={valueStyle}><Undefined/></div>
        }

        if (!to) {
            return <div className={'fw-normal'} style={valueStyle}>{value}</div>
        }
        if (typeof to === 'string') {
            return <Link to={to} style={valueStyle}><div className={'fw-normal'}>{value}</div></Link>
        }
        if (to.external) {
            const external_path = to.path.includes('http', 0) ? to.path : `https://${to.path}`
            return <a href={external_path} target={'_blank'} rel={"noreferrer"} style={valueStyle} className={'fw-normal to-ellipsis ws-nowrap'}>{value}</a>
        }
        return <a href={to.path} target={'_blank'} rel={"noreferrer"} style={valueStyle} className={'fw-normal to-ellipsis ws-nowrap'}>{value}</a>
    }

    return (
        <div
            className={`px-2 py-1 d-grid border-bottom ${highlight ? 'border-primary' : ''}`}
            style={{
                gridTemplateColumns: `repeat(auto-fit, minmax(${size}rem, 1fr))`
            }}
        >
            {
                label
                    ? <div className={'fw-bold'} style={labelStyle}>
                        {
                            tooltip
                                ? <TooltipText text={tooltip} placement={'right'}>
                                    {label}
                                </TooltipText>
                                : label
                        }
                    </div>
                    : null
            }
            { makeValue(value, to) }
        </div>
    )
}