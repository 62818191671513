import React from "react";
import {TemplateFile} from "../../../model";
import {ColumnDef} from "@tanstack/react-table";
import { DateCell} from "../../../component/list/cell";
import {EntityListTemplate} from "../../../component/template/EntityListTemplate";
import {TemplateFileRepository} from "../../../repository";

export const ListTemplateFile = () => {

    const columns: ColumnDef<TemplateFile>[] = [
        {
            header: "Nom",
            accessorKey: 'name',
            id: 'name',
        },
        {
            header: "Permission",
            accessorFn: (data: TemplateFile) => data.permission ?? 'Accès libre',
            id: 'permission',
        },
        {
            header: 'Version',
            accessorKey: 'version',
            id: 'version',
        },
        {
            header: `Répertoire`,
            accessorKey: 'directory.name',
            id: 'directory.name'
        },
        {
            header: `Dernière mise à jour`,
            accessorKey: `updatedAt`,
            id: `updatedAt`,
            cell: DateCell
        }
    ]

    return (
        <EntityListTemplate<TemplateFile>
            repository={new TemplateFileRepository()}
            title={`Document`}
            columns={columns}
            rearrangeable
        />
    )
}