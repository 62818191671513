import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Item} from "../types/Item"

type TooltipTextProps = {
    text: Item,
    placement?: 'right' | 'left' | 'top' | 'bottom',
    children: Item,
}

export const TooltipText = ({ children, placement, text } : TooltipTextProps) => {
    const tooltip = () => {
        return <Tooltip>
            <div>
                {text}
            </div>
        </Tooltip>
    }

    return (
        <OverlayTrigger
            placement={placement || 'bottom'}
            overlay={tooltip()}
        >
            {
                ({ref, ...triggerHandler}) => (
                    <span
                        ref={ref}
                        {...triggerHandler}
                        className={'gaped-5'}
                    >
                        <span>{ children }</span>
                        <sup className={'text-primary select-none cursor-help'}>?</sup>
                    </span>
                )
            }
        </OverlayTrigger>
    )
}