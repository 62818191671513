import React from "react";
import {TileCategoryRepository} from "../../../repository";
import {TileCategory} from "../../../model";
import {EntityReadTemplate} from "../../../component/template/EntityReadTemplate";

export const ReadTileCategory = () => {

    return (
        <EntityReadTemplate<TileCategory>
            repository={new TileCategoryRepository()}
            title={{
                page: `Catégorie`,
                schema: `$0`,
                params: [`name`]
            }}
            attributes={[
                { label: `Nom`, key: 'name' },
                { label: `Description`, key: 'description' },
            ]}
        />
    )
}