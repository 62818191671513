import {Entity} from "../../../model";
import {Badge} from "react-bootstrap";
import React from "react";
import {CellContext} from "@tanstack/react-table";
import {blackOrWhite} from "../../../utils/color";

export const ColorCell = <E extends Entity>(props: CellContext<E, any>) => {
    const color = props.getValue() || `#000`
    return (
        <Badge className={'w-100'} bg={''} style={{backgroundColor: `${color}`, color: blackOrWhite(color)}}>
            {`${color}`}
        </Badge>
    )
}