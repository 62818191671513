import * as React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Navigate,
    Route
} from 'react-router-dom'

import {useKeycloak} from '@react-keycloak/web'

import {Home} from '../../page/Home'
import {Template} from "../../page/Template";
import {Documentation} from "../../page/Documentation";
import {Documents} from "../../page/Documents";

import {RequireAuth} from '../security/PrivateRoute'
import {HandleStatus} from "./HandleStatus";

import {
    ListTile,
    ReadTile,
    UpdateTile,
    CreateTile
} from "../../page/administration/tile";
import {
    ListTileState,
    ReadTileState,
    UpdateTileState,
    CreateTileState
} from "../../page/administration/tileState";
import {
    ListTileCategory,
    ReadTileCategory,
    UpdateTileCategory,
    CreateTileCategory
} from "../../page/administration/tileCategory";
import {
    ListTemplateDirectory,
    ReadTemplateDirectory,
    CreateTemplateDirectory,
    UpdateTemplateDirectory,
} from "../../page/administration/templateDirectory";
import {
    CreateTemplateFile,
    ListTemplateFile,
    ReadTemplateFile,
    UpdateTemplateFile
} from "../../page/administration/templateFile";
import {
    CreateInternalDocument,
    ListInternalDocument, ReadInternalDocument,
    UpdateInternalDocument
} from "../../page/administration/internalDocument";
import {
    CreateInternalDirectory,
    ListInternalDirectory,
    ReadInternalDirectory,
    UpdateInternalDirectory
} from "../../page/administration/internalDirectory";
import {InternalDirectoryRepository, InternalDocumentRepository} from "../../repository";
import {Face} from "../../page/toolkit/face/Face";

type BaseRoute = {
    type: `route`,
    path: string,
    permission?: string,
}

export type AppRoute =
    (HiddenRoute | VisibleRoute)
    &
    (PublicRoute | AdminRoute)


type HiddenRoute = BaseRoute & {
    isHidden: true
}

type VisibleRoute = BaseRoute & {
    isHidden?: false,
    label: string
}

type PublicRoute = BaseRoute & {
    isAdmin?: false,
    element: JSX.Element
}

type AdminRoute = BaseRoute & {
    isAdmin: true,
    list: JSX.Element,
    read: JSX.Element,
    update: JSX.Element,
    create: JSX.Element,
}

export type RoutesContainer = {
    type: `container`,
    label: string,
    routes: AppRoute[],
    isAdmin: boolean,
    permission?: string,
}

export const AppRoutes: (AppRoute | RoutesContainer)[] = [
    {
        type: `route`,
        path: '/',
        element: <RequireAuth><Home/></RequireAuth>,
        label: 'Vos services'
    },
    {
        type: `route`,
        path: '/:status',
        element: <RequireAuth><HandleStatus/></RequireAuth>,
        isHidden: true
    },
    {
        type: `route`,
        path: '/template',
        element: <RequireAuth><Template/></RequireAuth>,
        label: 'Modèles de fichiers'
    },
    {
        type: `route`,
        path: '/help',
        element: <RequireAuth><Documentation/></RequireAuth>,
        label: 'Aide en ligne'
    },
    {
        type: `route`,
        path: '/internal_documents',
        permission: 'default-agent',
        element: <RequireAuth permission={'default-agent'}>
            <Documents
                dirRepository={new InternalDirectoryRepository()}
                docRepository={new InternalDocumentRepository()}
                title={`Documents internes`}
            />
        </RequireAuth>,
        label: 'Documents internes'
    },
    {
        type: `container`,
        label: 'Tuiles',
        isAdmin: true,
        routes: [
            {
                type: `route`,
                path: '/admin/tiles',
                list: <RequireAuth permission={'admin'}><ListTile/></RequireAuth>,
                read: <RequireAuth permission={'admin'}><ReadTile/></RequireAuth>,
                update: <RequireAuth permission={'admin'}><UpdateTile/></RequireAuth>,
                create: <RequireAuth permission={'admin'}><CreateTile/></RequireAuth>,
                label: 'Tuiles',
                isAdmin: true,
            },
            {
                type: `route`,
                path: '/admin/tile_states',
                list: <RequireAuth permission={'admin'}><ListTileState/></RequireAuth>,
                read: <RequireAuth permission={'admin'}><ReadTileState/></RequireAuth>,
                update: <RequireAuth permission={'admin'}><UpdateTileState/></RequireAuth>,
                create: <RequireAuth permission={'admin'}><CreateTileState/></RequireAuth>,
                label: 'États des tuiles',
                isAdmin: true
            },
            {
                type: `route`,
                path: '/admin/tile_categories',
                list: <RequireAuth permission={'admin'}><ListTileCategory/></RequireAuth>,
                read: <RequireAuth permission={'admin'}><ReadTileCategory/></RequireAuth>,
                update: <RequireAuth permission={'admin'}><UpdateTileCategory/></RequireAuth>,
                create: <RequireAuth permission={'admin'}><CreateTileCategory/></RequireAuth>,
                label: 'Catégories de tuiles',
                isAdmin: true
            },
        ]
    },
    {
        type: `container`,
        label: 'Modèles de fichiers',
        isAdmin: true,
        routes: [
            {
                type: `route`,
                path: '/admin/files',
                list: <RequireAuth permission={'admin'}><ListTemplateFile/></RequireAuth>,
                read: <RequireAuth permission={'admin'}><ReadTemplateFile/></RequireAuth>,
                update: <RequireAuth permission={'admin'}><UpdateTemplateFile/></RequireAuth>,
                create: <RequireAuth permission={'admin'}><CreateTemplateFile/></RequireAuth>,
                label: 'Modèles de fichiers',
                isAdmin: true,
            },
            {
                type: `route`,
                path: '/admin/directories',
                list: <RequireAuth permission={'admin'}><ListTemplateDirectory/></RequireAuth>,
                read: <RequireAuth permission={'admin'}><ReadTemplateDirectory/></RequireAuth>,
                update: <RequireAuth permission={'admin'}><UpdateTemplateDirectory/></RequireAuth>,
                create: <RequireAuth permission={'admin'}><CreateTemplateDirectory/></RequireAuth>,
                label: 'Répertoires',
                isAdmin: true
            }
        ]
    },
    {
        type: `container`,
        label: 'Documents internes',
        isAdmin: true,
        routes: [
            {
                type: `route`,
                path: '/admin/internal_documents',
                list: <RequireAuth permission={'admin'}><ListInternalDocument/></RequireAuth>,
                read: <RequireAuth permission={'admin'}><ReadInternalDocument/></RequireAuth>,
                update: <RequireAuth permission={'admin'}><UpdateInternalDocument/></RequireAuth>,
                create: <RequireAuth permission={'admin'}><CreateInternalDocument/></RequireAuth>,
                label: 'Documents internes',
                isAdmin: true,
            },
            {
                type: `route`,
                path: '/admin/internal_directories',
                list: <RequireAuth permission={'admin'}><ListInternalDirectory/></RequireAuth>,
                read: <RequireAuth permission={'admin'}><ReadInternalDirectory/></RequireAuth>,
                update: <RequireAuth permission={'admin'}><UpdateInternalDirectory/></RequireAuth>,
                create: <RequireAuth permission={'admin'}><CreateInternalDirectory/></RequireAuth>,
                label: 'Répertoires',
                isAdmin: true
            }
        ]
    },
    {
        type: `container`,
        permission: 'default-agent',
        label: 'Boite à outils',
        isAdmin: false,
        routes: [
            {
                type: `route`,
                path: '/toolkit/face',
                element: <RequireAuth permission={'default-agent'}><Face/></RequireAuth>,
                label: 'Contrôle FACE',
            }
        ]
    }
]

export const AppRouter = () => {
    const {initialized} = useKeycloak();

    if (!initialized) {
        return <div>Loading...</div>
    }

    const buildRoutes = (item: AppRoute | RoutesContainer): JSX.Element | JSX.Element[] => {
        if (item.type === 'route') {
            if (item.isAdmin) {
                return [
                    <Route path={`${item.path}`} element={item.list}/>,
                    <Route path={`${item.path}/:id`} element={item.read}/>,
                    <Route path={`${item.path}/:id/edit`} element={item.update}/>,
                    <Route path={`${item.path}/create`} element={item.create}/>,
                ]
            }
            return <Route path={item.path} element={item.element}/>
        }

        return item.routes
            .map(route => buildRoutes(route))
            .flat()
    }

    return (
        <Router>
            <Routes>
                {
                    AppRoutes.map(buildRoutes).flat()
                }

                <Route path="/admin" element={<Navigate to={'/admin/tiles'}/>}/>

                <Route path={'*'} element={<Navigate to={'/NOT_FOUND'}/>}/>
            </Routes>
        </Router>
    )
}