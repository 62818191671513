import {CrudDataList, CrudRepository} from ".";
import Keycloak from "keycloak-js";
import axios from "redaxios";
import {InternalDirectory} from "../model";

export class InternalDirectoryRepository extends CrudRepository<InternalDirectory> {
    constructor(keycloak: Keycloak | null = null) {
        super('internal_directories', "PATCH", keycloak)
        this.contentTypes["UPDATE"] = "application/merge-patch+json"
        this.show = this.show.bind(this)
    }

    public show(): Promise<CrudDataList<InternalDirectory>> {
        return new Promise<CrudDataList<InternalDirectory>>((resolve, reject) => {
            axios({
                url:`${process.env.REACT_APP_SERVER_URL}/show/${this.entityCode}`,
                method: 'GET',
                headers: this.headers
            })
                .then((response: any) => {
                    resolve({
                        nbItems: response.data['hydra:totalItems'],
                        data: this.jsonToEntities(
                            response.data['hydra:member']
                        ),
                    });
                })
                .catch((error : any) => {
                    reject(error);
                })
        })
    }

    protected jsonToEntity(json: any): InternalDirectory | null {
        if (!json) return null;
        return {
            id: json.id,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            deletedAt: json.deletedAt,
            name: json.name,
            permission: json.permission,
            documents: json.documents,
            filteredDocuments: json.filteredDocuments,
            numberOfDocuments: json.numberOfDocuments,
        }
    }

    protected entityToJson(entity: InternalDirectory): any {
        return super.entityToJson(entity);
    }
}