import React from "react";
import {OverlayTrigger} from "react-bootstrap";
import {Item} from "../core/types";

type ComponentWithTooltipProps = {
    tooltip: Item,
    children: Item,
}

export const ComponentWithTooltip = (props: ComponentWithTooltipProps) : JSX.Element | null => {

    if (props.children === null) return null;
    if (props.tooltip === null) return typeof props.children !== 'object' ? <span>{props.children}</span> : props.children;

    return (
        <OverlayTrigger
            placement={'top'}

            overlay={typeof props.tooltip !== 'object' ? <span>{props.tooltip}</span> : props.tooltip}
        >
            {typeof props.children !== 'object' ? <span>{props.children}</span> : props.children}
        </OverlayTrigger>
    )
}