import React from "react";
import {Tile} from "../../../model";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";
import {TileCategoryRepository, TileRepository} from "../../../repository";
import {TileStateRepository} from "../../../repository";

export const CreateTile = () => {
    return (
        <EntityFormTemplate<Tile>
            mode={'create'}
            repository={new TileRepository()}
            title={{
                page: `Tuile`,
                schema: `Ajouter une tuile`
            }}
            fields= {[
                { label: `Nom`, id: 'name', type: 'text', hint: 'Utiliser le caractère # pour forcer un saut de ligne.' },
                { label: `Description`, id: 'description', type: 'text' },
                { label: `État`, id: 'state', type: 'relation', repository: new TileStateRepository() },
                { label: `Catégorie`, id: 'category', type: 'relation', repository: new TileCategoryRepository() },
                { label: `Image`, id: 'imageFile', type: 'file' },
                { label: `URL cible`, id: 'url', type: 'text' },
                { label: `Permission`, id: 'permission', type: 'text' },
            ]}
        />
    )
}