import {ReorderModal, ReorderModalProps} from "./ReorderModal";
import React from "react";
import {Button} from "react-bootstrap";

export type ReorderButtonProps = ReorderModalProps & {
    rearrangeable: boolean,
    show: boolean,
    handleShow: () => void,
}

export const ReorderButton = ( props : ReorderButtonProps ) => {
    if (!props.rearrangeable) { return null; }

    return (
        <>
            <Button
                onClick={props.handleShow}
            >
                Réorganiser
            </Button>
            <ReorderModal
                {...props}
            />
        </>
    )
}