import React, {useState} from "react";
import {Modal, Button, ButtonGroup} from "react-bootstrap";
import {ColumnDef} from "@tanstack/react-table";
import './reoderModal.scss'
import {Eye, EyeOff} from "lucide-react";
import { Reorder } from "framer-motion";

type ColumnsState = {
    columnVisibility: Record<string, boolean>,
    columnOrder: string[],
}

export type ReorderModalProps = {
    state: ColumnsState,
    columnsDef: ColumnDef<any>[],
    show: boolean,
    handleClose: any,
    handleSave: any,
}
export const ReorderModal = (props : ReorderModalProps) => {

    const { state, columnsDef, handleClose, handleSave, show } = props;

    const initialColumnsState = state;
    const [columnsState, setColumnsState] = useState(state)

    const [reset, setReset] = useState(false);

    const onReorder = (newColumnsOrder: any[]) => {
        setColumnsState({
            columnOrder: newColumnsOrder,
            columnVisibility: columnsState.columnVisibility
        })
    }

    const onHiddenChange = (id: string) => {

        const newColumnVisibility: Record<string, boolean> = {...columnsState.columnVisibility};

        newColumnVisibility[id] = !columnsState.columnVisibility[id]

        setColumnsState({
            columnOrder: columnsState.columnOrder,
            columnVisibility: newColumnVisibility
        })
    }

    const localHandleSave = () => {
        setReset(false);
        handleSave(columnsState)
    }

    const handleCancel = () => {
        setColumnsState(initialColumnsState);
        setReset(false);
        handleClose();
    }

    const handleReset = () => {
        setReset(false);
        handleSave({
            columnOrder: [],
            columnVisibility: {}
        })
    }

    const isAllVisible = () : boolean => {
        let result = true;
        if (Object.keys(columnsState.columnVisibility).length === 0) { return result; }
        Object.values(columnsState.columnVisibility).forEach(visibility => {
            if (!visibility) { result = false; }
        })
        return result;
    }

    const setAllVisible = (visibility: boolean) : void => {
        const newColumnVisibility = {...columnsState.columnVisibility}
        columnsDef.forEach(col => {
            newColumnVisibility[`${col.id}`] = visibility;
        })
        setColumnsState({
            columnOrder: columnsState.columnOrder,
            columnVisibility: newColumnVisibility
        })
    }

    const HideAll = () => {

        const allVisible = isAllVisible();

        return (
            <div className={'reorder-modal-hideall'}>
                <CheckEye
                    visible={allVisible}
                    onHiddenChange={
                        () => setAllVisible(!allVisible)
                    }
                />
                <span>{allVisible ? 'Masquer tout' : 'Afficher tout'}</span>
            </div>
        )
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className={'reorder-modal'}
        >
            <Modal.Header>
                <Modal.Title>Modifier les colonnes</Modal.Title>
            </Modal.Header>

            <Modal.Body className={'no-select'}>
                <div>Faites glisser les colonnes pour les réorganiser :</div>
                <HideAll/>
                <DragDrop
                    columnsState={columnsState}
                    columnsDef={columnsDef}
                    onReorder={onReorder}
                    onHiddenChange={onHiddenChange}
                />
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                {
                    reset ?
                        <Button className={'footer-button'} variant={"primary"} onClick={handleReset}>
                            Confirmer
                        </Button>
                        :
                        <Button className={'footer-button'} variant={"secondary"} onClick={() => setReset(true)}>
                            Réinitialiser
                        </Button>
                }
                    <Button className={'footer-button'} variant="secondary" onClick={handleCancel}>
                        Annuler
                    </Button>
                    <Button
                        className={'footer-button'}
                        variant="primary"
                        onClick={() => localHandleSave()}
                    >
                        Enregistrer
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    )
}

type DragDropProps = {
    columnsState: any,
    columnsDef: ColumnDef<any>[],
    onReorder: (newOrder: any[]) => void,
    onHiddenChange : (id: string) => void,
}

const DragDrop = (props : DragDropProps) => {

    const { columnsState, columnsDef, onReorder, onHiddenChange } = props;

    const columns = prepareColumns(columnsState, columnsDef);

    const [dragging, setDragging] = useState<string|undefined>(undefined)

    return (
        <Reorder.Group
            as={'div'}
            className={`reorder-modal-group`}
            axis={"y"}
            values={columns.map(c => c.id)}
            onReorder={onReorder}
        >
            {columns.map(column => (
                <Reorder.Item
                    as={"div"}
                    className={`reorder-modal-item ${dragging === column.id ? 'dragging' : ''}`}
                    key={column.id}
                    value={column.id}
                    onPointerDown={() => setDragging(column.id)}
                    onPointerUp={() => setDragging(undefined)}
                >
                    <CheckEye
                        id={column.id}
                        visible={!column.visible}
                        onHiddenChange={onHiddenChange}
                    />
                    {`${column.header}`}
                </Reorder.Item>
            ))}
        </Reorder.Group>
    )
}

const prepareColumns = (columnsState: ColumnsState, columnsDef: ColumnDef<any>[]) => {
    let order: string[];
    if (!columnsState.columnOrder) { order = columnsDef.map(col => col.id!) }
    else if (columnsState.columnOrder.length === 0) { order = columnsDef.map(col => col.id!) }
    else { order = columnsState.columnOrder }

    return order.map((id: string) => {
        const column = columnsDef.find(col => col.id === id)!;
        return ({
            id: id,
            header: column.header,
            visible:
                columnsState.columnVisibility[id] !== undefined
                && !columnsState.columnVisibility[id]
        })
    })
}

type CheckEyeProps = {
    id?: any,
    visible: any,
    onHiddenChange: any,
}

const CheckEye = (props : CheckEyeProps) => {

    const { id, visible, onHiddenChange } = props;

    return (
        <div
            onClick={() => onHiddenChange(id)}
        >
            {visible ? <Eye/> : <EyeOff/>}
        </div>
    )
}