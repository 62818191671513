import {CrudDataList, CrudRepository} from ".";
import Keycloak from "keycloak-js";
import {TemplateDirectory} from "../model";
import axios from "redaxios";

export class TemplateDirectoryRepository extends CrudRepository<TemplateDirectory> {
    constructor(keycloak: Keycloak | null = null) {
        super('template_directories', "PATCH", keycloak)
        this.contentTypes["UPDATE"] = "application/merge-patch+json"
        this.show = this.show.bind(this)
    }

    public show(): Promise<CrudDataList<TemplateDirectory>> {
        return new Promise<CrudDataList<TemplateDirectory>>((resolve, reject) => {
            axios({
                url:`${process.env.REACT_APP_SERVER_URL}/show/${this.entityCode}`,
                method: 'GET',
                headers: this.headers
            })
                .then((response: any) => {
                    resolve({
                        nbItems: response.data['hydra:totalItems'],
                        data: this.jsonToEntities(
                            response.data['hydra:member']
                        ),
                    });
                })
                .catch((error : any) => {
                    reject(error);
                })
        })
    }

    protected jsonToEntity(json: any): TemplateDirectory | null {
        if (!json) return null;
        return {
            id: json.id,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            deletedAt: json.deletedAt,
            name: json.name,
            permission: json.permission,
            templateFiles: json.templateFiles,
            filteredTemplateFiles: json.filteredTemplateFiles,
            numberOfFiles: json.numberOfFiles,
        }
    }

    protected entityToJson(entity: TemplateDirectory): any {
        return super.entityToJson(entity);
    }
}