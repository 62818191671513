import {Form} from 'react-bootstrap'
import {Field} from "formik";
import {FileFormField} from "./FileFormField";
import {FieldType} from "../../core/types/fieldType";
import {CrudRepository} from "../../repository";
import {Entity} from "../../model";
import {RelationFormField} from "./RelationFormField";
import Keycloak from "keycloak-js";
import {BooleanFormField} from "./BooleanFormField";

export type FormFieldProps = {
    id: string,
    label: string,
    type: FieldType,
    repository?: CrudRepository<Entity>
    keycloak?: Keycloak | null,
    hint?: string,
    error?: any,
    touched?: any
}

export const FormField = (props: FormFieldProps) => {

    const defaultFormField = (
        <>

            <Form.Label htmlFor={props.id}>
                {props.label}
                {props.hint && <span className={'ps-2 fst-italic text-primary'}>{props.hint}</span>}
            </Form.Label>
            <Field
                as={Form.Control}
                type={props.type}
                id={props.id}
                name={props.id}
                isInvalid={props.touched && !!props.error}
                isValid={props.touched && !props.error}
            />
        </>
    )

    let selectedFormField;

    switch (props.type) {
        case 'file':
            selectedFormField = <FileFormField {...props} />;
            break;
        case 'relation':
            selectedFormField = <RelationFormField {...props} />;
            break;
        case 'boolean':
            selectedFormField = <BooleanFormField {...props} />;
            break;
        case 'text':
        default:
            selectedFormField = defaultFormField
    }

    return (
        <Form.Group id={props.id} className={'my-2'}>
            {selectedFormField}
            {
                props.touched && props.error
                    ? <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                        {props.error}
                    </Form.Control.Feedback>
                    : null
            }
        </Form.Group>
    )
}