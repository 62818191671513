import React, {useEffect, useState} from "react";
import {Page} from "../component/Page";
import {Alert, Container} from "react-bootstrap";
import {Document} from "../component/Document";
import axios from "redaxios";
import {Doc} from "../core/types";
import {Loading} from "../component/Loading/Loading";
import {Video} from "../component/Vidéo";
// import * as Sentry from "@sentry/react";

export const Documentation = () => {

    const [data, setData] = useState<Doc[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_CONFIG_URL}/help.json`, {
            headers: {
                'Cache-Control': 'no-cache',
            }
        })
            .then((res) => setData(res.data))
            .catch(
                ({response}) => {
                    const errorMessage = `${response.status} : ${response.data?.message ?? 'Erreur inconnue'}`;
                    setError(errorMessage);
                    // Sentry.captureMessage(errorMessage, "error");
                })
            .finally(() => setLoading(false))
    }, [])

    const buildContent = () => {
        if (loading) {
            return (
                <Container className={'d-flex-center flex-column'}>
                    <Loading/>
                </Container>
            )
        }

        if (error) {
            return (
                <Container className={'d-flex-center flex-column'}>
                    <Alert variant={'warning'}>
                        Une erreur est survenue.
                    </Alert>
                </Container>
            )
        }

        if (data.length === 0) {
            return (
                <Container>
                    <Alert variant={'warning'}>
                        Aucune documentation disponible actuellement.
                    </Alert>
                </Container>
            )
        }

        return (
            <Container>
                {
                    data.map(d => {
                        switch (d.type) {
                            case 'video':
                                return <Video document={d}/>;
                            case 'file':
                            default:
                                return <Document document={d}/>
                        }
                    })
                }
            </Container>
        )
    }

    return (
        <Page>
            <div className={'text-center position-relative w-100'}>
                <div
                    className={`ff-netto bg-dark swatch-primary text-center m-0 fs-3 w-100`}
                    style={{
                        lineHeight: '5rem',
                        height: '5rem',
                        display: 'inline-block',
                        padding: '0 2rem',
                    }}
                >
                    {`Aide en ligne`}
                </div>
            </div>
            {
                buildContent()
            }
        </Page>
    )
}