import React, {Component, createRef} from "react";
import {Point} from "../../core/types";

type Circle = {
    center: Point,
    radius: number,
    speed: number,
    color: string,
}

type LoadingState = {
    bigStep: number,
    smallStep: number,
}

export class Loading extends Component<any, LoadingState> {

    private readonly canvas : React.RefObject<HTMLCanvasElement>;
    private ctx: CanvasRenderingContext2D | null;

    private readonly CANVAS_WIDTH : number = 200;
    private readonly CANVAS_HEIGHT : number = 200;

    private readonly bigCircle: Circle = {
        center: { x: this.CANVAS_WIDTH * .5, y: this.CANVAS_HEIGHT * .5 },
        radius: 50,
        speed: 2,
        color: '#A0328C'
    }

    private readonly smallCircle: Circle = {
        center: { x: this.CANVAS_WIDTH * .5, y: this.CANVAS_HEIGHT * .5 },
        radius: 30,
        speed: 2,
        color: '#46505A'
    }

    constructor(props: any) {
        super(props);

        this.state = {
            bigStep: 0,
            smallStep: 0.2
        }

        this.canvas = createRef();
        this.ctx = null;
    }

    componentDidMount() {
        if (!this.canvas.current) return;
        this.ctx = this.canvas.current.getContext('2d');
        this.animate()
    }

    componentWillUnmount() {

    }

    accelerateInterpolator = (x: number) => x * x
    decelerateInterpolator = (x: number) => 1 - ((1 - x) * (1 - x))

    drawCircle = (circle: Circle, step: number) => {
        if (!this.ctx) return;
        this.ctx.beginPath();

        const start = this.accelerateInterpolator(step) * circle.speed;
        const end = this.decelerateInterpolator(step) * circle.speed;

        this.ctx.arc(circle.center.x, circle.center.y, circle.radius, (start - 0.5) * Math.PI, (end - 0.5) * Math.PI);
        this.ctx.lineWidth = 3;
        this.ctx.strokeStyle = circle.color;
        this.ctx.stroke();
    }

    animate = () => {
        if (this.ctx) {
            this.ctx.clearRect(0, 0, this.CANVAS_WIDTH, this.CANVAS_HEIGHT);
            this.setState(prevState => {
                return {
                    bigStep: prevState.bigStep > 1 ? 0 : prevState.bigStep + 0.01,
                    smallStep: prevState.smallStep > 1 ? 0 : prevState.smallStep + 0.01
                }
            })

            this.drawCircle(this.bigCircle, this.state.bigStep);
            this.drawCircle(this.smallCircle, this.state.smallStep);
        }
        requestAnimationFrame(this.animate)
    }

    render() {
        return (
            <canvas ref={this.canvas} height={this.CANVAS_HEIGHT} width={this.CANVAS_WIDTH}>
                Chargement...
            </canvas>
        )
    }
}