import {CrudRepository} from ".";
import Keycloak from "keycloak-js";
import axios from "redaxios";
import {TemplateFile} from "../model";
import {isEmpty} from "../utils/control/isEmpty";

export class TemplateFileRepository extends CrudRepository<TemplateFile> {
    constructor(keycloak: Keycloak | null = null) {
        super('template_files', "POST", keycloak)
    }

    protected jsonToEntity(json: any): TemplateFile | null {
        if (!json) return null;
        return {
            id: json.id,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            deletedAt: json.deletedAt,
            name: json.name,
            description: json.description,
            permission: json.permission,
            templateFile: json.templateFile,
            templateFileName: json.templateFileName,
            thumbnailFile: json.thumbnailFile,
            thumbnailFileName: json.thumbnailFileName,
            version: json.version,
            directory: json.directory,
        }
    }

    public showTemplateFiles(fields ?: string) : Promise<TemplateFile[] | null> {
        return new Promise<TemplateFile[] | null>((resolve, reject) => {
            axios({
                url:`${process.env.REACT_APP_SERVER_URL}/show_template_files`,
                method: 'GET',
                headers: this.headers
            })
                .then((response : any) => {
                    resolve(this.jsonToEntities(response.data['hydra:member']));
                })
                .catch((error : any) => {
                    reject(error);
                })
        })
    }

    protected async entityToJson(entity: TemplateFile): Promise<any> {
        const formData = new FormData();

        const exclude = ['templateFile', 'thumbnailFile']

        if (!isEmpty(entity.templateFile)) {
            const blob = new Blob([entity.templateFile], {type: entity.templateFile.type });
            const filename = entity.templateFile.name && entity.templateFile.name.split('\\').pop();
            formData.append('templateFile', blob, filename);
        }

        if (!isEmpty(entity.thumbnailFile)) {
            const blob = new Blob([entity.thumbnailFile], {type: entity.thumbnailFile.type });
            const filename = entity.thumbnailFile.name && entity.thumbnailFile.name.split('\\').pop();
            formData.append('thumbnailFile', blob, filename);
        }

        Object.keys(entity).forEach((key) => {
            if (!exclude.includes(key))
            {
                const value = entity.hasOwnProperty(key) ? (entity as any)[key] : undefined;
                if (value !== undefined && value !== null) {
                    switch (key) {
                        case 'directory':
                            formData.append(key, this.getIRI((entity as any)[key], 'template_directories'))
                            break;
                        default:
                            formData.append(key, (entity as any)[key])
                            break;
                    }
                }
            }
        })

        return formData;
    }
}