import React from "react";
import {TileStateRepository} from "../../../repository";
import {TileState} from "../../../model";
import {ColumnDef} from "@tanstack/react-table";
import {ColorCell, DateCell} from "../../../component/list/cell";
import {EntityListTemplate} from "../../../component/template/EntityListTemplate";
import {useKeycloak} from "@react-keycloak/web";

export const ListTileState = () => {

    const { keycloak } = useKeycloak();

    const columns: ColumnDef<TileState>[] = [
        {
            header: "Nom",
            accessorKey: 'name',
            id: 'name',
        },
        {
            header: 'Couleur',
            accessorKey: 'color',
            id: 'color',
            cell: ColorCell,
        },
        {
            header: 'Description',
            accessorKey: 'description',
            id: 'description',
        },
        {
            header: `Dernière mise à jour`,
            accessorKey: `updatedAt`,
            id: `updatedAt`,
            cell: DateCell,
        }
    ]

    return (
        <EntityListTemplate<TileState>
            repository={new TileStateRepository(keycloak)}
            title={`État`}
            columns={columns}
            rearrangeable
        />
    )
}