import React from "react";
import {InternalDocument} from "../../../model";
import {ColumnDef} from "@tanstack/react-table";
import { DateCell} from "../../../component/list/cell";
import {EntityListTemplate} from "../../../component/template/EntityListTemplate";
import {InternalDocumentRepository} from "../../../repository";

export const ListInternalDocument = () => {

    const columns: ColumnDef<InternalDocument>[] = [
        {
            header: "Nom",
            accessorKey: 'name',
            id: 'name',
        },
        {
            header: "Permission",
            accessorFn: (data: InternalDocument) => data.permission ?? 'Accès libre',
            id: 'permission',
        },
        {
            header: 'Version',
            accessorKey: 'version',
            id: 'version',
        },
        {
            header: `Répertoire`,
            accessorKey: 'directory.name',
            id: 'directory.name'
        },
        {
            header: `Dernière mise à jour`,
            accessorKey: `updatedAt`,
            id: `updatedAt`,
            cell: DateCell
        }
    ]

    return (
        <EntityListTemplate<InternalDocument>
            repository={new InternalDocumentRepository()}
            title={`Document`}
            columns={columns}
            rearrangeable
        />
    )
}