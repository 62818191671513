import React from "react";
import {SponsorImage} from "./SponsorImage";
import logo from "../image/logo_blanc_min.png"

export const Footer = () => {
    return (
        <div
            className={'position-fixed d-flex justify-content-between align-items-center bottom-0 start-0 w-100 bg-dark swatch-dark ff-titilllium '}
            style={{ height: 120, zIndex: 9 }}
        >
            <div className={'d-flex-center h-75 ps-3'}>
                <img
                    alt={`logo Te61`}
                    src={logo}
                    height={'75%'}
                />
            </div>
            <div className={`d-none d-md-flex align-items-center flex-column swatch-primary`}>
                <span style={{ fontSize: '.8rem' }}>{`Des questions sur le fonctionnement ?`}</span>
                <span style={{ fontSize: '.8rem' }}>{`N'hésitez pas à consulter la documentation ou à nous contacter.`}</span>
                <span style={{ fontSize: '.8rem' }}>{`6 rue de Gâtel 61250 Valframbert`}</span>
                <a href={`mailto:accueil@te61.fr`} style={{ fontSize: '.8rem' }}>{`accueil@te61.fr`}</a>
                <span style={{ fontSize: '.8rem' }}>{`02 33 32 83 13`}</span>
            </div>
            <SponsorImage/>
        </div>
    )
}