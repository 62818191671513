import * as Yup from "yup";
import {getFieldValue} from "../objectManipulation";

if (process.env.REACT_APP_ENV === 'dev') {
    console.log("Chargement des méthodes yup personnalisées.")
}

const validFileExtensions : { [key: string] : string[] } = {
    image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],
    document: [
        "pdf",
        "doc", "docx",
        "xls", "xlsx",
        "ppt", "pptx",
        "odt", "ods", "odp",
        "rtf",
        "txt",
        "md",
    ]
};

Yup.addMethod(
    Yup.string,
    "version",
    function (msg) {
        return this.test('version', msg, function (value) {
            const {path, createError} = this;

            if (value && value.match(/^\d+\.\d+$/)) { return true; }

            createError({ path, message: msg })
        })
    }
)

Yup.addMethod(
    Yup.string,
    "permission",
    function (msg) {
        return this.test('permission', msg, function (value) {
            const {path, createError} = this;

            if (!value) { return true; }
            if (value === "") { return true; }
            if (value.match(/^[a-z]+(?:-[a-z]+)*$/)) { return true; }

            createError({ path, message: msg })
        })
    }
)

Yup.addMethod(
    Yup.mixed,
    "relation",
    function (type, msg) {
        return this.test('relation', msg, function (value: any) {
            const {path, createError} = this;

            if (typeof value === "string") { return true; }
            if (getFieldValue(value, "@type") === type) { return true; }

            createError({ path, message: msg })
        })
    }
)

Yup.addMethod(
    Yup.mixed,
    "fileSize",
    function (size_in_ko, msg) {
        return this.test('fileSize', msg, function (value: any) {
            const {path, createError} = this;

            if (!value) { return true; }

            const max_size = size_in_ko * 1024;
            if (value.size && value.size <= max_size) { return true; }

            createError({ path, message: msg })
        })
    }
)

Yup.addMethod(
    Yup.mixed,
    "fileType",
    function (type, msg) {
        return this.test('fileType', msg, function (value: any) {
            const {path, createError} = this;

            if (!value) { return true; }

            if(value.name && validFileExtensions[type].indexOf(value.name.toLowerCase().split('.').pop()) > -1) {
                return true;
            }

            createError({ path, message: msg })
        })
    }
)