import React from "react";
import {TileStateRepository} from "../../../repository";
import {TileState} from "../../../model";
import {EntityReadTemplate} from "../../../component/template/EntityReadTemplate";

export const ReadTileState = () => {
    return (
        <EntityReadTemplate<TileState>
            repository={new TileStateRepository()}
            title={{
                page: `État`,
                schema: `$0`,
                params: [`name`]
            }}
            attributes={[
                { label: `Nom`, key: 'name' },
                { label: `Couleur`, key: 'color', fn: (value: any) => <span style={{ color: `${value}` }}>{`${value}`}</span> },
                { label: `Description`, key: 'description' },
            ]}
        />
    )
}