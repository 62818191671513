import React from "react";
import {IconProps} from "./index";

type WavyProps = IconProps & {
    left?: string,
    right?: string,
    fill?: string
}

export const Wavy = ( { left = '#29b8ce', right = '#9f358b', fill = '#FFFFFF', className, style } : WavyProps ) => {
    return (
        <svg
            viewBox="0 0 696.07 100"
            className={className ?? ''}
            style={style && {...style}}
            preserveAspectRatio={'none'}
        >
            <defs>
                <clipPath id="clip-path-wave">
                    <path fill={'none'}
                          d="M696.07,0C622.2,9.41,548.74,22.85,404.26,24.06,245.19,24.06,128.42,10.85,0,27.59V47.2C239.77,28.47,466.66,61.29,696.07,63.32Z"/>
                </clipPath>
                <linearGradient id="degrade" x1="-33.47" y1="352.05" x2="-32.47" y2="352.05"
                                gradientTransform="matrix(696.07, 0, 0, -696.07, 23299.12, 245082.04)"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={left}/>
                    <stop offset="1" stopColor={right}/>
                </linearGradient>
            </defs>
            <g>
                <g>
                    <g>
                        <rect width="696.07" height="70" y="30" fill={fill}/>
                    </g>
                    <g clipPath={'url(#clip-path-wave)'}>
                        <rect fill={'url(#degrade)'} width="696.07" height="63.32"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}