import {Button, Spinner} from 'react-bootstrap';
import React from "react";

//Prorpiétés du bouton
type FormButtonProps = {
    isValid: boolean;
    isSubmitting?: boolean;
};

/**
 * Champ type button de validation
 */
export const FormSubmitButton = (props: FormButtonProps) => {
    return (
        <Button disabled={!props.isValid || props.isSubmitting} variant="primary" type="submit" className={'gaped-10'}>
            {props.isSubmitting ? <Spinner as={'span'} animation={'border'} size={'sm'}/> : null}
            {props.isSubmitting ? ` ` : null}
            <span>Enregistrer</span>
        </Button>
    );
};
