import { Form } from 'react-bootstrap'
import {FormFieldProps} from "./FormField";
import {Field} from "formik";

export const BooleanFormField = (props: FormFieldProps) => {
    return (
        <Field
            as={Form.Check}
            style={{ minHeight: '38px' }}
            type={'checkbox'}
            id={props.id}
            name={props.id}
            label={props.label}
            isInvalid={props.touched && !!props.error}
            isValid={props.touched && !props.error}
        />
    )
}