import {
    TableState,
    ColumnOrderState,
    useReactTable,
    ColumnDef,
    getCoreRowModel,
    flexRender,
    CellContext,
    Row
} from '@tanstack/react-table';
import {Entity} from "../../model";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Table} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {ReorderButton} from "./action/ReorderButton";
import './simpleList.scss'


type BasicProps<E extends Entity> = {
    data: E[],
    columns: ColumnDef<E>[],
    createPath?: string,
}

type ResizableListProps = {
    resizable: true,
    initialState: Partial<TableState>,
    onSave: (config: any) => void,
}

type NonResizableProps = {
    resizable?: false,
    initialState?: Partial<TableState>,
    onSave?: (config: any) => void,
}

type RearrangeableProps = {
    rearrangeable: true,
    initialState: Partial<TableState>,
    onSave: (config: any) => void,
}

type NonRearrangeableProps = {
    rearrangeable?: false,
    initialState?: Partial<TableState>,
    onSave?: (config: any) => void,
}

type SimpleListProps<E extends Entity> =
    BasicProps<E>
    & (ResizableListProps | NonResizableProps)
    & (RearrangeableProps | NonRearrangeableProps)

export const SimpleList = <E extends Entity>(props: SimpleListProps<E>) => {

    const location = useLocation();
    const navigate = useNavigate();

    const { columns, data, rearrangeable, resizable } = props;

    // Modale pour la réorganisation des colonnes
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSave = (newState: any) => {
        if (rearrangeable) {
            setColumnOrder(newState.columnOrder)
            setColumnVisibility(newState.columnVisibility)
        }
        setShow(false);
    }

    const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(
        rearrangeable ? props.initialState.columnVisibility || {} : {}
    )
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
        rearrangeable ? props.initialState.columnOrder || [] : []
    )
    const [columnSizing, setColumnSizing] = useState<Record<string, number>>(
        resizable ? props.initialState.columnSizing || {} : {}
    )

    const onSaveFn: (config: any) => void = useMemo(
        () => {
            if (props.onSave !== undefined) {
                return props.onSave
            }
            return () => {}
        },
        [props.onSave]
    )

    useEffect(() => {
        if (rearrangeable || resizable) {
            onSaveFn({
                columnOrder,
                columnVisibility,
                columnSizing
            })
        }
    }, [columnSizing, columnVisibility, columnOrder, rearrangeable, resizable, onSaveFn])

    const DefaultCell = useCallback(
        (props: CellContext<E, any>) => {
            const { cell } = props;
            return (
                <div>{cell.renderValue()}</div>
            )
        },
        []
    )

    const DefaultColumn: Partial<ColumnDef<E>> = useMemo(
        () => ({
            cell: DefaultCell,
            size: 150,
            minSize: 50,
            maxSize: Number.MAX_SAFE_INTEGER
        }),
        [DefaultCell]
    )

    const table = useReactTable({
        data: data,
        columns: columns,
        columnResizeMode: 'onEnd',
        state: {
            columnVisibility: rearrangeable ? columnVisibility : {},
            columnOrder: rearrangeable ? columnOrder : [],
            columnSizing: resizable ? columnSizing : {},
        },
        onColumnVisibilityChange: rearrangeable ? setColumnVisibility : () => {},
        onColumnOrderChange: rearrangeable ? setColumnOrder : () => {},
        onColumnSizingChange: resizable ? setColumnSizing : () => {},
        getCoreRowModel: getCoreRowModel(),
        defaultColumn: DefaultColumn,
    })

    const enableFooter = props.columns.reduce(
        (result: boolean, column: ColumnDef<E>) => {
            if (result) return result;
            return column.footer !== undefined
        },
        false
    )

    const handleRowClick = (e: React.MouseEvent, row: Row<E>) => {
        let path = `${location.pathname}/${row.original.id}`;

        // Navigate on left click
        if (e.button === 0) {
            navigate(path);
        }
        // Open in new tab on middle click
        else if (e.button === 1) {
            window.open(path, '_blank');
        }
    }

    return (
        <div className="p-2">
            <div className={'my-1 simple-list-header'}>
                <Button
                    href={props.createPath || `${location.pathname}/create`}
                    variant={'success'}
                >
                    Ajouter
                </Button>
                <div className={`filler`}>

                </div>
                <ReorderButton
                    state={table.getState()}
                    columnsDef={table._getColumnDefs()}
                    rearrangeable={!!rearrangeable}
                    show={show}
                    handleShow={handleShow}
                    handleClose={handleClose}
                    handleSave={handleSave}
                />
            </div>
            <Table striped bordered hover className={`simple-list`}>
                <thead>
                {
                    table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))
                }
                </thead>
                <tbody>
                {
                    table.getRowModel().rows.map(row => (
                        <tr
                            key={row.id}
                            onMouseDown={(e) => handleRowClick(e, row)}
                        >
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))
                }
                </tbody>
                {
                    enableFooter
                    ? <tfoot>
                        {table.getFooterGroups().map(footerGroup => (
                            <tr key={footerGroup.id}>
                                {footerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.footer,
                                                header.getContext()
                                            )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </tfoot>
                    : null
                }
            </Table>
        </div>
    )
}