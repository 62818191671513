import React from "react";
import {ColumnDef} from "@tanstack/react-table";
import {Tile} from "../../../model";
import {DateCell} from "../../../component/list/cell";
import {EntityListTemplate} from "../../../component/template/EntityListTemplate";
import {TileRepository} from "../../../repository";
import {useKeycloak} from "@react-keycloak/web";

export const ListTile = () => {

    const { keycloak } = useKeycloak();

    const columns: ColumnDef<Tile>[] = [
        {
            header: "Nom",
            accessorKey: 'name',
            id: 'name',
        },
        {
            header: `État`,
            accessorKey: `state.name`,
            id: `state.name`,
        },
        {
            header: `Catégorie`,
            accessorKey: 'category.name',
            id: 'category.name',
        },
        {
            header: `URL cible`,
            accessorKey: `url`,
            id: `url`,
        },
        {
            header: `Permission`,
            accessorKey: `permission`,
            id: `permission`,
        },
        {
            header: `Dernière mise à jour`,
            accessorKey: `updatedAt`,
            id: `updatedAt`,
            cell: DateCell,
        },
    ]

    return (
        <EntityListTemplate<Tile>
            repository={new TileRepository(keycloak)}
            title={`Tuiles`}
            columns={columns}
            rearrangeable
        />
    )
}