import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { InternalDirectory } from "../../../model";
import { DateCell } from "../../../component/list/cell";
import { EntityListTemplate } from "../../../component/template/EntityListTemplate";
import { InternalDirectoryRepository } from "../../../repository";

export const ListInternalDirectory = () => {

    const columns: ColumnDef<InternalDirectory>[] = [
        {
            header: "Nom",
            accessorKey: 'name',
            id: 'name',
        },
        {
            header: "Permission",
            accessorFn: (data: InternalDirectory) => data.permission ?? 'Accès libre',
            id: 'permission',
        },
        {
            header: 'Nombre de documents',
            accessorKey: 'numberOfDocuments',
            id: 'numberOfDocuments',
        },
        {
            header: `Dernière mise à jour`,
            accessorKey: `updatedAt`,
            id: `updatedAt`,
            cell: DateCell
        }
    ]

    return (
        <EntityListTemplate<InternalDirectory>
            repository={new InternalDirectoryRepository()}
            title={`Répertoire`}
            columns={columns}
            rearrangeable
        />
    )
}