import React, {CSSProperties} from "react";
import logo from "../image/france_relance_min.png";

type SponsorImageProps = {
    className?: string;
    style?: CSSProperties;
}

export const SponsorImage = (props: SponsorImageProps) => {
    return (
        <a
            href={'https://www.economie.gouv.fr/plan-de-relance'}
            target={'_blank'}
            rel={'noreferrer'}
            className={`h-100 ${props.className ?? ''}`}
            style={props.style ?? {}}
        >
            <img
                src={logo}
                alt={'logo France Relance'}
                className={'h-100 w-100 p-1'}
                style={{
                    objectFit: 'contain'
                }}
            />
        </a>
    )
}