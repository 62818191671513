import React from "react";
import {TileStateRepository} from "../../../repository";
import {TileState} from "../../../model";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";

export const UpdateTileState = () => {
    return (
        <EntityFormTemplate<TileState>
            mode={'update'}
            repository={new TileStateRepository()}
            title={{
                page: `État`,
                schema: `Modifier l'état "$0"`,
                params: [`name`]
            }}
            fields= {[
                { label: `Nom`, id: 'name', type: 'text' },
                { label: `Couleur`, id: 'color', type: 'color' },
                { label: `Description`, id: 'description', type: 'text' },
                { label: `Désactive la tuile`, id: 'disable', type: 'boolean' },
            ]}
        />
    )
}