import React from "react";
import {InternalDirectoryRepository} from "../../../repository";
import {InternalDirectory} from "../../../model";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";
import * as Yup from "yup";

export const UpdateInternalDirectory = () => {

    return (
        <EntityFormTemplate<InternalDirectory>
            mode={'update'}
            repository={new InternalDirectoryRepository()}
            title={{
                page: `Répertoire`,
                schema: `Modifier le répertoire "$0"`,
                params: [`name`]
            }}
            fields= {[
                { label: `Nom`, id: 'name', type: 'text' },
            ]}
            validationSchema={
                Yup.object().shape({
                    name: Yup.string()
                        .min(3, 'Minimum 3 caractères.')
                        .max(200, 'Maximum 200 caractères.')
                        .required('Obligatoire'),
                })
            }
        />
    )
}