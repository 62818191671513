import React from "react";
import {TemplateDirectoryRepository, TemplateFileRepository} from "../../../repository";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";
import {TemplateFile} from "../../../model";
import * as Yup from "yup";

export const CreateTemplateFile = () => {

    return (
        <EntityFormTemplate<TemplateFile>
            mode={'create'}
            repository={new TemplateFileRepository()}
            title={{
                page: `Document`,
                schema: `Ajouter un document`
            }}
            fields={[
                {label: `Nom`, id: 'name', type: 'text'},
                {label: `Description`, id: 'description', type: 'text'},
                {label: `Permission`, id: 'permission', type: 'text'},
                {label: `Version`, id: 'version', type: 'text'},
                {label: `Répertoire`, id: 'directory', type: 'relation', repository: new TemplateDirectoryRepository()},
                {label: `Fichier`, id: 'templateFile', type: 'file', hint:`(taille < 5Mo)` },
                {label: `Miniature`, id: 'thumbnailFile', type: 'file', hint:`(taille < 5Mo)` },
            ]}
            validationSchema={
                Yup.object().shape({
                    name: Yup.string()
                        .min(3, 'Minimum 3 caractères.')
                        .max(200, 'Maximum 200 caractères.')
                        .required('Obligatoire'),
                    permission: Yup.string()
                        .permission("Doit être composé de lettres minuscules et de tirets."),
                    version: Yup.string()
                        .version("Format x.y attendu.")
                        .required('Obligatoire'),
                    directory: Yup.mixed()
                        .relation("TemplateDirectory", "Répertoire invalide.")
                        .required('Obligatoire'),
                    templateFile: Yup.mixed()
                        .fileType("document", "Type de fichier non supporté.")
                        .fileSize(5000, "Fichier trop volumineux."),
                    thumbnailFile: Yup.mixed()
                        .fileType("image", "Type de fichier non supporté.")
                        .fileSize(5000, "Fichier trop volumineux."),
                })
            }
        />
    )
}