import {Entity} from "../../model";
import {Alert, Container} from "react-bootstrap";
import {Attribute as AttributeType, LinkDef} from "../../types/Attribute";
import React, {useCallback} from "react";
import {Attribute} from "../Attribute";
import {ToLocaleDateString} from "../../utils/date";
import {Item} from "../../types/Item";
import {CrudRepository} from "../../repository";
import {useNavigate, useParams} from "react-router-dom";
import {Loading} from "../Loading/Loading";
import {Page} from "../Page";
import {getFieldValue} from "../../utils/objectManipulation";
import {useKeycloak} from "@react-keycloak/web";
import {useFetchOne} from "../../utils/hook/useFetch";

type EntityReadTemplateProps<E extends Entity> = {
    repository: CrudRepository<E>
    /**
     * Title of the page
     */
    title: { page: string, schema: string; params?: string[] },
    /**
     * Attributes of the Entity to display
     */
    attributes?: AttributeType[],
    /**
     * Add custom elements
     */
    children?: ((data: E) => Item);
}

/**
 * Display Entity attributes
 */
export const EntityReadTemplate = <E extends Entity>(props: EntityReadTemplateProps<E>) => {

    const { id } = useParams<{ id: string }>();

    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const repository = props.repository;

    const readOne = useCallback(() =>  {
        repository.setKeycloak(keycloak);
        return repository.read(`${id}`)
    },[keycloak, id, repository])

    const [ loading, data, error ] = useFetchOne(readOne);

    const footer : AttributeType[] = [
        { label: 'Créé le', key: 'createdAt', fn: ToLocaleDateString },
        { label: 'Dernière modification le', key: 'updatedAt', fn: ToLocaleDateString },
    ]

    const handleDelete = () => {
        repository.delete(`${id}`)
            .then(() => {
                navigate(-1)
            })
            // .catch(({status, message}) => {
            //     setError(`[${status}] ${message ?? 'Erreur inconnue'}`);
            // })
    }

    const buildTo = (data: any, to?: LinkDef) : LinkDef | undefined => {
        if (!to) { return undefined; }
        if (typeof to === 'string') {
            if (data.hasOwnProperty(to)) {
                return data[to];
            }
            return to;
        }
        if (data.hasOwnProperty(to.path)) {
            return { path: data[to.path], external: to.external};
        }
        return to;
    }

    const buildTitle = (): string => {
        if (props.title.params !== undefined) {
            for (let i = 0; i < props.title.params.length; i++) {
                let paramsValue: any = data;
                const fields = props.title.params[i].split('.');
                for (let field of fields) {
                    const fieldElems = field.split(':');
                    field = fieldElems[0];
                    paramsValue = paramsValue?.hasOwnProperty(field) ? paramsValue[field] : undefined;
                    // Si le paramètre est suivi d'un 'filtre' (ex: dateEnd:year) on applique un traitement
                    if (fieldElems.length > 1) {
                        switch (fieldElems[1])
                        {
                            case 'year':
                                paramsValue = new Date(paramsValue)?.toLocaleDateString(
                                    `${process.env.REACT_APP_LOCALE}`,
                                    {
                                        year: 'numeric'
                                    }
                                );
                                break;

                            case 'month':
                                paramsValue = new Date(paramsValue)?.toLocaleDateString(
                                    `${process.env.REACT_APP_LOCALE}`,
                                    {
                                        month: 'long',
                                        year: 'numeric'
                                    }
                                );
                                break;

                            case 'day':
                                paramsValue = new Date(paramsValue)?.toLocaleDateString(
                                    `${process.env.REACT_APP_LOCALE}`,
                                    {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric'
                                    }
                                );
                                break;

                            default:
                                break;
                        }
                    }
                }

                props.title.schema = props.title.schema.replace(
                    `$${i}`,
                    paramsValue !== undefined ? paramsValue : '😕'
                );
            }
        }
        return props.title.schema;
    };

    const buildContent = () => {
        if (loading) {
            return (
                <div className={'d-flex-center'}>
                    <Loading/>
                </div>
            )
        }

        if (error) {
            return (
                <Alert variant={'danger'}>
                    {error}
                </Alert>
            )
        }

        if (data === null) {
            return <Alert variant={`danger`}>
                {`Donnée introuvable`}
            </Alert>
        }

        return (
            <Container>
                <div className={'text-center my-3'}>
                    <h2>
                        { buildTitle() }
                    </h2>
                </div>
                <div className={'my-3'}>
                    {
                        props.attributes
                            ? props.attributes.map(({label, key, fn, to}) =>
                                {
                                    return <Attribute
                                        key={key}
                                        label={label}
                                        value={fn ? fn((data as any)[key]) : getFieldValue(data, key)}
                                        to={buildTo(data, to)}
                                    />
                                }
                            )
                            : null
                    }
                    {
                        props.children !== undefined
                            ? <div>{ props.children(data) }</div>
                            : null
                    }
                </div>
                <div className={`d-flex justify-content-end gap-3`}>
                    {
                        footer.map(({label, key, fn}) => (
                            <div key={key}>
                                <span>{ label }</span>
                                <span> </span>
                                <span>
                                    {
                                        fn
                                            ? fn(getFieldValue(data, key))
                                            : getFieldValue(data, key)
                                    }
                                </span>
                            </div>
                        ))
                    }
                </div>
            </Container>
        )
    }

    return (
        <Page type={"admin"} allowPrev={!loading} allowEdit={!loading} allowDelete={!loading} handleDelete={handleDelete}>
            <div className={'text-center position-relative w-100'}>
                <div
                    className={`ff-netto bg-dark swatch-primary text-center m-0 fs-3 w-100`}
                    style={{
                        lineHeight: '5rem',
                        height: '5rem',
                        display: 'inline-block',
                        padding: '0 2rem',
                    }}
                >
                    {`Administration • ${props.title.page}`}
                </div>
            </div>
            <div>
                { buildContent() }
            </div>
        </Page>
    )
}