import {CrudRepository} from "../repository";
import {useKeycloak} from "@react-keycloak/web";
import {Page} from "../component/Page";
import {useFetchAll} from "../utils/hook/useFetch";
import {Accordion, Alert, Badge, Container} from "react-bootstrap";
import {Loading} from "../component/Loading/Loading";
import React from "react";
import {Directory, Document} from "../model";
import {File} from "lucide-react";
import {DocumentDisplay} from "../component/DocumentDisplay";

type DocumentsProps<DIR extends Directory, DOC extends Document> = {
    dirRepository: CrudRepository<DIR>,
    docRepository: CrudRepository<DOC>,
    title: string
}

export const Documents = <DIR extends Directory, DOC extends Document>(props: DocumentsProps<DIR, DOC>) => {
    const { dirRepository, docRepository, title } = props;
    const {keycloak} = useKeycloak();

    dirRepository.setKeycloak(keycloak);

    const [loading, data, error] = useFetchAll<DIR>(dirRepository.show)

    const buildContent = () => {
        const filledDirectories = data
            .filter(dir => dir.filteredDocuments.length > 0)

        if (filledDirectories.length === 0) {
            return (
                <Container>
                    <Alert variant={'warning'}>
                        Aucun document disponible actuellement.
                    </Alert>
                </Container>
            )
        }

        return (
            <Container>
                <Accordion className={'py-4'}>
                    {
                        filledDirectories
                            .map((dir, index) =>
                                <Accordion.Item eventKey={`${dir.id}`}>
                                    <Accordion.Header>
                                        <div className={'d-flex justify-content-between w-100 pe-5'}>
                                            <div>
                                                <File/>
                                                {` ${dir.name}`}
                                            </div>
                                            <Badge bg={"dark"}>
                                                {`${dir.filteredDocuments.length} document${dir.filteredDocuments.length > 1 ? 's' : ''}`}
                                            </Badge>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            dir.filteredDocuments.map((doc, index) =>
                                                <DocumentDisplay key={index} document={doc} entityCode={docRepository.getEntityCode()}/>
                                            )
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                    }
                </Accordion>
            </Container>
        )
    }

    if (loading) {
        return (
            <Page>
                <Container className={'d-flex-center flex-column'} style={{minHeight: '100%'}}>
                    <Loading/>
                </Container>
            </Page>

        )
    }

    if (error) {
        return (
            <Page>
                <Container className={'d-flex-center flex-column'} style={{ minHeight: '100%' }}>
                    <Alert variant={'danger'}>
                        {error}
                    </Alert>
                </Container>
            </Page>
        )
    }

    return (
        <Page>
            <div
                className={`ff-netto bg-dark swatch-primary text-center m-0 fs-3 w-100`}
                style={{
                    lineHeight: '5rem',
                    height: '5rem',
                    display: 'inline-block',
                    padding: '0 2rem',
                }}
            >
                {title}
            </div>
            {
                buildContent()
            }
        </Page>
    )
}