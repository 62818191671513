import React from "react";
import {InternalDirectoryRepository} from "../../../repository";
import {EntityFormTemplate} from "../../../component/template/EntityFormTemplate";
import {InternalDirectory} from "../../../model";
import * as Yup from 'yup';

export const CreateInternalDirectory = () => {

    return (
        <EntityFormTemplate<InternalDirectory>
            mode={'create'}
            repository={new InternalDirectoryRepository()}
            title={{
                page: `Répertoire`,
                schema: `Ajouter un répertoire`
            }}
            fields= {[
                { label: `Nom`, id: 'name', type: 'text' },
                { label: `Permission`, id: 'permission', type: 'text' },
            ]}
            validationSchema={
                Yup.object().shape({
                    name: Yup.string()
                        .min(3, 'Minimum 3 caractères.')
                        .max(200, 'Maximum 200 caractères.')
                        .required('Obligatoire'),
                })
            }
        />
    )
}