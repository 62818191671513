import React from "react";
import {Navigation} from "./Navigation";
import {Footer} from "./Footer";
import {Wavy} from "../image/svgAsElement";
import {useNavigate} from "react-router-dom";
import {Button, ButtonGroup} from "react-bootstrap";
import {Item} from "../core/types";


type PageProps = {
    children: JSX.Element | JSX.Element[],
    type?: 'default' | 'admin'
    allowPrev?: boolean
    allowEdit?: boolean
    allowDelete?: boolean
    handleDelete?: () => void
}

export const Page = (props: PageProps) => {

    const navigate = useNavigate()

    const buildButtons = (): Item => {
        if (props.type !== 'admin') { return null; }

        const buttons = []

        if (props.allowPrev) {
            buttons.push(<Button key={'goback'} variant={'light'} onClick={() => navigate(-1)}>Retour</Button>)
        }

        if (props.allowEdit) {
            buttons.push(<Button key={'edit'} variant={'primary'} onClick={() => navigate(`edit`)}>Modifier</Button>)
        }

        if (props.allowDelete && props.handleDelete) {
            buttons.push(<Button key={'edit'} variant={'danger'} onClick={() => props.handleDelete!()}>Supprimer</Button>)
        }

        if (buttons.length === 0) { return null; }

        return (
            <div className={'d-flex justify-content-center gap-3'}>
                <ButtonGroup>
                    { buttons }
                </ButtonGroup>
            </div>
        )
    }

    return (
        <div>
            <Navigation type={props.type || 'default'}/>
            <div
                style={{ height: 'calc(100vh - 180px)', overflowY: 'auto'}}
            >
                {props.children}
                { buildButtons() }
            </div>
            <Footer/>
            <div
                className={'position-absolute top-0 start-0 end-0 bottom-0 overflow-hidden'}
                style={{
                    zIndex: -2
                }}
            >
                <Wavy
                    className={'position-absolute'}
                    style={{
                        top: '65%',
                        left: 0,
                        zIndex: -1,
                        height: 250,
                        width: '100%'
                    }}
                />
            </div>
        </div>
    )
}