export function parseJson(txt: any, reviver?: ((this:any, key: string, value: any) => any) | undefined) {

    const errorTranslations: { [key: string]: string } = {
        'unexpected token': 'Caractère inattendu',
        'unexpected end of JSON input': 'Fin inattendue du JSON',
        'unexpected string': 'Chaîne inattendue',
        'unexpected number': 'Nombre inattendu',
        'unexpected boolean': 'Booléen inattendu',
        'unexpected null': 'Null inattendu',
        'unexpected character': 'Caractère inattendu',
        'unexpected identifier': 'Identifiant inattendu',
        'unexpected non-whitespace character': "Caractère inattendu",
        'expected double-quoted property name': 'Une propriété était attendue',
        'bad control character in string literal': 'Caractère de contrôle incorrect dans une chaîne de caractères littérale',
        "expected ',' or ']' after array element": `Caractère ',' ou ']' attendu après un élément de tableau`,
        "expected ':' after property name in object" : "Caractère ':' attendu après un nom de propriété"
    };

    const translateError = (message: string) : string => {
        for (const [key, translation] of Object.entries(errorTranslations)) {
            if (message.includes(key)) {
                return translation;
            }
        }
        return message;
    }

    const errorLocation = (message: string): { line: number, col: number} => {
        const positionMatch = message.match(/at line (\d+) column (\d+)/);
        if (positionMatch && positionMatch.length >= 3) {
            const line = parseInt(positionMatch[1]);
            const col = parseInt(positionMatch[2]);
            return { line, col };
        }
        return { line: -1, col: -1 };
    }

    const getLine = (txt: string, line: number): string | undefined => {
        const lines = txt.split('\r\n');
        if (line < 1 || line > lines.length) { return undefined }
        return lines[line - 1];
    }

    try {
        return JSON.parse(txt, reviver)
    }
    catch (e: any) {
        if (typeof txt !== 'string') {
            const isEmptyArray = Array.isArray(txt) && txt.length === 0
            const errorMessage = 'Impossible de lire ' +
                (isEmptyArray ? 'un tableau vide' : String(txt))
            throw new TypeError(errorMessage)
        }

        const {line, col} = errorLocation(e.message)

        e.message = translateError(e.message)

        e.lines = {
            prev: getLine(txt, line - 1),
            error: getLine(txt, line),
            next: getLine(txt, line + 1)
        }

        e.location = { line: line, col: col }

        throw e
    }
}