import React from 'react';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from 'react-dom/client';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './core/security/keycloak'
import { AppRouter } from './core/routing/routes'
import './app.scss'

import reportWebVitals from './reportWebVitals';
import {Loading} from "./component/Loading/Loading";
import './utils/control/yupCustom';

const eventLogger = (event: unknown, error: unknown) => {
    // console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
    localStorage.setItem('token', JSON.stringify(tokens));
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Sentry.init({
//     dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 0.1,
// });

root.render(
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<div className={'d-flex-center'} style={{ height: '100vh' }}><Loading/></div>}
      initOptions={{
          onLoad: 'check-sso',
          // silentCheckSsoRedirectUri: window.origin + '/assets/silent-check-sso.html'
      }}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <AppRouter/>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
